@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  ::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    background: theme(scrollbar.default.background);
    border-left: 0 solid theme(scrollbar.default.background);
    border-right: 0 solid theme(scrollbar.default.background);
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: theme(scrollbar.default.thumb);
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: theme(scrollbar.hover.thumb);
  }

  ::-webkit-scrollbar-corner {
    background: theme(scrollbar.hover.background);
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    margin: 0;
    background-color: theme(colors.greyscale.hiberBlack);
  }

  a,
  a:visited {
    color: theme(colors.text.default);
    text-decoration: none;
  }

  a:hover {
    color: theme(colors.link.hover.text);
  }

  label {
    color: theme(colors.label.default);
  }

  div,
  span,
  p {
    color: theme(colors.text.default);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: theme(colors.heading.default);
  }

  * {
    font-family: 'Inter';
  }
}

@layer utilities {
  .break-words {
    overflow-wrap: break-word;
    word-break: break-word;
  }

  .scrollbar-hidden {
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .thumbnail-filter {
    filter: saturate(140%) contrast(110%) brightness(105%);
  }

  .bottom-gradient {
    -webkit-mask-image: linear-gradient(
      to bottom,
      transparent 0%,
      black 16px,
      black calc(100% - 16px),
      transparent 100%
    );
    mask-image: linear-gradient(to bottom, transparent 0%, black 16px, black calc(100% -16px), transparent 100%);
  }
}

@property --num-hibux {
  syntax: '<integer>';
  initial-value: 0;
  inherits: false;
}
