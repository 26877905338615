
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  ::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    background: #1B1C20;
    border-left: 0 solid #1B1C20;
    border-right: 0 solid #1B1C20;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: #343540;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #E02B36;
  }

  ::-webkit-scrollbar-corner {
    background: #1B1C20;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    margin: 0;
    background-color: #1B1C20;
  }

  a,
  a:visited {
    color: #FFFFFF;
    text-decoration: none;
  }

  a:hover {
    color: #E02B36;
  }

  label {
    color: #FFFFFF;
  }

  div,
  span,
  p {
    color: #FFFFFF;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #FFFFFF;
  }

  * {
    font-family: 'Inter';
  }
*, ::before, ::after{
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  ;
}
::backdrop{
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  ;
}
.container{
  width: 100%;
}
@media (min-width: 320px){
  .container{
    max-width: 320px;
  }
}
@media (min-width: 430px){
  .container{
    max-width: 430px;
  }
}
@media (min-width: 568px){
  .container{
    max-width: 568px;
  }
}
@media (min-width: 700px){
  .container{
    max-width: 700px;
  }
}
@media (min-width: 768px){
  .container{
    max-width: 768px;
  }
}
@media (min-width: 830px){
  .container{
    max-width: 830px;
  }
}
@media (min-width: 900px){
  .container{
    max-width: 900px;
  }
}
@media (min-width: 1024px){
  .container{
    max-width: 1024px;
  }
}
@media (min-width: 1366px){
  .container{
    max-width: 1366px;
  }
}
@media (min-width: 1440px){
  .container{
    max-width: 1440px;
  }
}
.pointer-events-none{
  pointer-events: none;
}
.pointer-events-auto{
  pointer-events: auto;
}
.\!visible{
  visibility: visible !important;
}
.visible{
  visibility: visible;
}
.invisible{
  visibility: hidden;
}
.collapse{
  visibility: collapse;
}
.static{
  position: static;
}
.fixed{
  position: fixed;
}
.absolute{
  position: absolute;
}
.relative{
  position: relative;
}
.inset-0{
  inset: 0px;
}
.-bottom-1{
  bottom: -0.25rem;
}
.-bottom-10{
  bottom: -2.5rem;
}
.-bottom-12{
  bottom: -3rem;
}
.-bottom-16{
  bottom: -4rem;
}
.-bottom-2{
  bottom: -0.5rem;
}
.-bottom-20{
  bottom: -5rem;
}
.-bottom-24{
  bottom: -6rem;
}
.-bottom-28{
  bottom: -7rem;
}
.-bottom-44{
  bottom: -11rem;
}
.-bottom-8{
  bottom: -2rem;
}
.-bottom-\[0\.5px\]{
  bottom: -0.5px;
}
.-left-\[209px\]{
  left: -209px;
}
.-right-0{
  right: -0px;
}
.-right-1{
  right: -0.25rem;
}
.-right-10{
  right: -2.5rem;
}
.-right-14{
  right: -3.5rem;
}
.-right-2{
  right: -0.5rem;
}
.-right-20{
  right: -5rem;
}
.-right-24{
  right: -6rem;
}
.-right-28{
  right: -7rem;
}
.-right-44{
  right: -11rem;
}
.-right-8{
  right: -2rem;
}
.-right-\[0\.4rem\]{
  right: -0.4rem;
}
.-right-\[1\.5px\]{
  right: -1.5px;
}
.-right-\[1px\]{
  right: -1px;
}
.-right-\[2px\]{
  right: -2px;
}
.-top-1{
  top: -0.25rem;
}
.-top-10{
  top: -2.5rem;
}
.-top-2{
  top: -0.5rem;
}
.-top-20{
  top: -5rem;
}
.-top-56{
  top: -14rem;
}
.-top-\[10px\]{
  top: -10px;
}
.bottom-0{
  bottom: 0px;
}
.bottom-1{
  bottom: 0.25rem;
}
.bottom-10{
  bottom: 2.5rem;
}
.bottom-11{
  bottom: 2.75rem;
}
.bottom-16{
  bottom: 4rem;
}
.bottom-2{
  bottom: 0.5rem;
}
.bottom-24{
  bottom: 6rem;
}
.bottom-3{
  bottom: 0.75rem;
}
.bottom-4{
  bottom: 1rem;
}
.bottom-5{
  bottom: 1.25rem;
}
.bottom-6{
  bottom: 1.5rem;
}
.bottom-\[-90px\]{
  bottom: -90px;
}
.bottom-\[5px\]{
  bottom: 5px;
}
.left-0{
  left: 0px;
}
.left-1\/2{
  left: 50%;
}
.left-2{
  left: 0.5rem;
}
.left-4{
  left: 1rem;
}
.left-8{
  left: 2rem;
}
.left-\[-4rem\]{
  left: -4rem;
}
.left-\[-68px\]{
  left: -68px;
}
.left-\[-80px\]{
  left: -80px;
}
.left-\[10px\]{
  left: 10px;
}
.left-\[11\%\]{
  left: 11%;
}
.left-\[14px\]{
  left: 14px;
}
.left-full{
  left: 100%;
}
.right-0{
  right: 0px;
}
.right-1{
  right: 0.25rem;
}
.right-10{
  right: 2.5rem;
}
.right-2{
  right: 0.5rem;
}
.right-3{
  right: 0.75rem;
}
.right-36{
  right: 9rem;
}
.right-4{
  right: 1rem;
}
.right-6{
  right: 1.5rem;
}
.right-8{
  right: 2rem;
}
.right-\[-16px\]{
  right: -16px;
}
.right-\[-170px\]{
  right: -170px;
}
.right-\[-2rem\]{
  right: -2rem;
}
.right-\[0\.2rem\]{
  right: 0.2rem;
}
.right-\[1px\]{
  right: 1px;
}
.right-\[3px\]{
  right: 3px;
}
.right-\[4\%\]{
  right: 4%;
}
.right-\[5px\]{
  right: 5px;
}
.top-0{
  top: 0px;
}
.top-1{
  top: 0.25rem;
}
.top-1\/3{
  top: 33.333333%;
}
.top-1\/4{
  top: 25%;
}
.top-16{
  top: 4rem;
}
.top-2{
  top: 0.5rem;
}
.top-3{
  top: 0.75rem;
}
.top-4{
  top: 1rem;
}
.top-6{
  top: 1.5rem;
}
.top-8{
  top: 2rem;
}
.top-\[-2px\]{
  top: -2px;
}
.top-\[-300px\]{
  top: -300px;
}
.top-\[-60px\]{
  top: -60px;
}
.top-\[0\.2rem\]{
  top: 0.2rem;
}
.top-\[11\%\]{
  top: 11%;
}
.top-\[14px\]{
  top: 14px;
}
.top-\[1px\]{
  top: 1px;
}
.top-\[27\%\]{
  top: 27%;
}
.top-\[354px\]{
  top: 354px;
}
.top-\[50\%\]{
  top: 50%;
}
.top-\[56px\]{
  top: 56px;
}
.top-\[calc\(50\%-2rem\)\]{
  top: calc(50% - 2rem);
}
.top-full{
  top: 100%;
}
.-z-10{
  z-index: -10;
}
.-z-20{
  z-index: -20;
}
.-z-\[0\]{
  z-index: 0;
}
.-z-\[1\]{
  z-index: -1;
}
.z-0{
  z-index: 0;
}
.z-10{
  z-index: 10;
}
.z-20{
  z-index: 20;
}
.z-30{
  z-index: 30;
}
.z-50{
  z-index: 50;
}
.z-\[-1\]{
  z-index: -1;
}
.z-\[1\]{
  z-index: 1;
}
.z-\[20\]{
  z-index: 20;
}
.z-\[2\]{
  z-index: 2;
}
.z-\[3\]{
  z-index: 3;
}
.z-\[5\]{
  z-index: 5;
}
.z-\[997\]{
  z-index: 997;
}
.z-\[998\]{
  z-index: 998;
}
.z-\[999\]{
  z-index: 999;
}
.z-\[9\]{
  z-index: 9;
}
.z-auto{
  z-index: auto;
}
.-order-1{
  order: -1;
}
.order-1{
  order: 1;
}
.order-2{
  order: 2;
}
.col-span-2{
  grid-column: span 2 / span 2;
}
.col-span-full{
  grid-column: 1 / -1;
}
.col-start-1{
  grid-column-start: 1;
}
.col-start-2{
  grid-column-start: 2;
}
.row-span-4{
  grid-row: span 4 / span 4;
}
.row-start-1{
  grid-row-start: 1;
}
.row-start-2{
  grid-row-start: 2;
}
.row-start-3{
  grid-row-start: 3;
}
.row-end-2{
  grid-row-end: 2;
}
.-m-1{
  margin: -0.25rem;
}
.m-0{
  margin: 0px;
}
.m-2{
  margin: 0.5rem;
}
.m-3{
  margin: 0.75rem;
}
.m-4{
  margin: 1rem;
}
.m-6{
  margin: 1.5rem;
}
.m-8{
  margin: 2rem;
}
.m-\[8px\]{
  margin: 8px;
}
.m-auto{
  margin: auto;
}
.-my-\[10vh\]{
  margin-top: -10vh;
  margin-bottom: -10vh;
}
.mx-2{
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.mx-4{
  margin-left: 1rem;
  margin-right: 1rem;
}
.mx-5{
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}
.mx-7{
  margin-left: 1.75rem;
  margin-right: 1.75rem;
}
.mx-8{
  margin-left: 2rem;
  margin-right: 2rem;
}
.mx-\[-1rem\]{
  margin-left: -1rem;
  margin-right: -1rem;
}
.mx-\[-6px\]{
  margin-left: -6px;
  margin-right: -6px;
}
.mx-\[1\.75rem\]{
  margin-left: 1.75rem;
  margin-right: 1.75rem;
}
.mx-auto{
  margin-left: auto;
  margin-right: auto;
}
.my-0{
  margin-top: 0px;
  margin-bottom: 0px;
}
.my-1{
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.my-2{
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.my-3{
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
.my-4{
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.my-6{
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.my-8{
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.my-\[10px\]{
  margin-top: 10px;
  margin-bottom: 10px;
}
.my-\[2px\]{
  margin-top: 2px;
  margin-bottom: 2px;
}
.-mr-2{
  margin-right: -0.5rem;
}
.-mt-2{
  margin-top: -0.5rem;
}
.-mt-\[6px\]{
  margin-top: -6px;
}
.mb-0{
  margin-bottom: 0px;
}
.mb-1{
  margin-bottom: 0.25rem;
}
.mb-10{
  margin-bottom: 2.5rem;
}
.mb-12{
  margin-bottom: 3rem;
}
.mb-14{
  margin-bottom: 3.5rem;
}
.mb-16{
  margin-bottom: 4rem;
}
.mb-2{
  margin-bottom: 0.5rem;
}
.mb-24{
  margin-bottom: 6rem;
}
.mb-28{
  margin-bottom: 7rem;
}
.mb-3{
  margin-bottom: 0.75rem;
}
.mb-32{
  margin-bottom: 8rem;
}
.mb-4{
  margin-bottom: 1rem;
}
.mb-44{
  margin-bottom: 11rem;
}
.mb-5{
  margin-bottom: 1.25rem;
}
.mb-6{
  margin-bottom: 1.5rem;
}
.mb-64{
  margin-bottom: 16rem;
}
.mb-72{
  margin-bottom: 18rem;
}
.mb-8{
  margin-bottom: 2rem;
}
.mb-\[2\.375rem\]{
  margin-bottom: 2.375rem;
}
.mb-\[76px\]{
  margin-bottom: 76px;
}
.ml-0{
  margin-left: 0px;
}
.ml-10{
  margin-left: 2.5rem;
}
.ml-16{
  margin-left: 4rem;
}
.ml-2{
  margin-left: 0.5rem;
}
.ml-20{
  margin-left: 5rem;
}
.ml-4{
  margin-left: 1rem;
}
.ml-5{
  margin-left: 1.25rem;
}
.ml-\[1\.5em\]{
  margin-left: 1.5em;
}
.ml-\[45px\]{
  margin-left: 45px;
}
.ml-\[6px\]{
  margin-left: 6px;
}
.ml-\[80px\]{
  margin-left: 80px;
}
.ml-auto{
  margin-left: auto;
}
.mr-0{
  margin-right: 0px;
}
.mr-1{
  margin-right: 0.25rem;
}
.mr-16{
  margin-right: 4rem;
}
.mr-2{
  margin-right: 0.5rem;
}
.mr-20{
  margin-right: 5rem;
}
.mr-3{
  margin-right: 0.75rem;
}
.mr-4{
  margin-right: 1rem;
}
.mr-8{
  margin-right: 2rem;
}
.mr-auto{
  margin-right: auto;
}
.mt-0{
  margin-top: 0px;
}
.mt-1{
  margin-top: 0.25rem;
}
.mt-10{
  margin-top: 2.5rem;
}
.mt-11{
  margin-top: 2.75rem;
}
.mt-12{
  margin-top: 3rem;
}
.mt-16{
  margin-top: 4rem;
}
.mt-2{
  margin-top: 0.5rem;
}
.mt-20{
  margin-top: 5rem;
}
.mt-24{
  margin-top: 6rem;
}
.mt-3{
  margin-top: 0.75rem;
}
.mt-4{
  margin-top: 1rem;
}
.mt-40{
  margin-top: 10rem;
}
.mt-5{
  margin-top: 1.25rem;
}
.mt-6{
  margin-top: 1.5rem;
}
.mt-8{
  margin-top: 2rem;
}
.mt-\[-1px\]{
  margin-top: -1px;
}
.mt-\[-40px\]{
  margin-top: -40px;
}
.mt-\[0\.125rem\]{
  margin-top: 0.125rem;
}
.mt-\[10px\]{
  margin-top: 10px;
}
.mt-\[1px\]{
  margin-top: 1px;
}
.mt-\[3vh\]{
  margin-top: 3vh;
}
.mt-\[7px\]{
  margin-top: 7px;
}
.box-border{
  box-sizing: border-box;
}
.box-content{
  box-sizing: content-box;
}
.block{
  display: block;
}
.inline-block{
  display: inline-block;
}
.inline{
  display: inline;
}
.flex{
  display: flex;
}
.inline-flex{
  display: inline-flex;
}
.table{
  display: table;
}
.grid{
  display: grid;
}
.contents{
  display: contents;
}
.hidden{
  display: none;
}
.aspect-\[0\.66\/1\]{
  aspect-ratio: 0.66/1;
}
.aspect-\[0\.9\]{
  aspect-ratio: 0.9;
}
.aspect-\[1\.2\]{
  aspect-ratio: 1.2;
}
.aspect-\[16\/8\]{
  aspect-ratio: 16/8;
}
.aspect-\[1\]{
  aspect-ratio: 1;
}
.aspect-\[2\/3\]{
  aspect-ratio: 2/3;
}
.aspect-\[232\/155\]{
  aspect-ratio: 232/155;
}
.aspect-\[4\/3\]{
  aspect-ratio: 4/3;
}
.aspect-square{
  aspect-ratio: 1 / 1;
}
.aspect-video{
  aspect-ratio: 16 / 9;
}
.size-0{
  width: 0px;
  height: 0px;
}
.size-16{
  width: 4rem;
  height: 4rem;
}
.size-2{
  width: 0.5rem;
  height: 0.5rem;
}
.size-20{
  width: 5rem;
  height: 5rem;
}
.size-4{
  width: 1rem;
  height: 1rem;
}
.size-6{
  width: 1.5rem;
  height: 1.5rem;
}
.size-8{
  width: 2rem;
  height: 2rem;
}
.size-\[14px\]{
  width: 14px;
  height: 14px;
}
.size-\[50px\]{
  width: 50px;
  height: 50px;
}
.size-\[75px\]{
  width: 75px;
  height: 75px;
}
.size-full{
  width: 100%;
  height: 100%;
}
.h-0{
  height: 0px;
}
.h-1{
  height: 0.25rem;
}
.h-1\/2{
  height: 50%;
}
.h-10{
  height: 2.5rem;
}
.h-11{
  height: 2.75rem;
}
.h-12{
  height: 3rem;
}
.h-14{
  height: 3.5rem;
}
.h-16{
  height: 4rem;
}
.h-2{
  height: 0.5rem;
}
.h-20{
  height: 5rem;
}
.h-24{
  height: 6rem;
}
.h-28{
  height: 7rem;
}
.h-4{
  height: 1rem;
}
.h-5{
  height: 1.25rem;
}
.h-6{
  height: 1.5rem;
}
.h-7{
  height: 1.75rem;
}
.h-8{
  height: 2rem;
}
.h-80{
  height: 20rem;
}
.h-9{
  height: 2.25rem;
}
.h-\[100dvh\]{
  height: 100dvh;
}
.h-\[100px\]{
  height: 100px;
}
.h-\[100vh\]{
  height: 100vh;
}
.h-\[10px\]{
  height: 10px;
}
.h-\[110px\]{
  height: 110px;
}
.h-\[12px\]{
  height: 12px;
}
.h-\[150\]{
  height: 150;
}
.h-\[150px\]{
  height: 150px;
}
.h-\[18px\]{
  height: 18px;
}
.h-\[1px\]{
  height: 1px;
}
.h-\[200px\]{
  height: 200px;
}
.h-\[22px\]{
  height: 22px;
}
.h-\[24px\]{
  height: 24px;
}
.h-\[25\%\]{
  height: 25%;
}
.h-\[250px\]{
  height: 250px;
}
.h-\[2px\]{
  height: 2px;
}
.h-\[30\%\]{
  height: 30%;
}
.h-\[30px\]{
  height: 30px;
}
.h-\[320px\]{
  height: 320px;
}
.h-\[4\.5rem\]{
  height: 4.5rem;
}
.h-\[400px\]{
  height: 400px;
}
.h-\[4rem\]{
  height: 4rem;
}
.h-\[50\%\]{
  height: 50%;
}
.h-\[512px\]{
  height: 512px;
}
.h-\[58px\]{
  height: 58px;
}
.h-\[5px\]{
  height: 5px;
}
.h-\[60\%\]{
  height: 60%;
}
.h-\[62px\]{
  height: 62px;
}
.h-\[66px\]{
  height: 66px;
}
.h-\[69px\]{
  height: 69px;
}
.h-\[6px\]{
  height: 6px;
}
.h-\[700px\]{
  height: 700px;
}
.h-\[75px\]{
  height: 75px;
}
.h-\[78px\]{
  height: 78px;
}
.h-\[7px\]{
  height: 7px;
}
.h-\[80\%\]{
  height: 80%;
}
.h-\[80vh\]{
  height: 80vh;
}
.h-\[88px\]{
  height: 88px;
}
.h-\[94px\]{
  height: 94px;
}
.h-\[auto\]{
  height: auto;
}
.h-\[calc\(100dvh-56px\)\]{
  height: calc(100dvh - 56px);
}
.h-\[inherit\]{
  height: inherit;
}
.h-auto{
  height: auto;
}
.h-body{
  height: calc(100vh - 56px);
}
.h-dvh{
  height: 100dvh;
}
.h-full{
  height: 100%;
}
.h-max{
  height: -moz-max-content;
  height: max-content;
}
.h-screen{
  height: 100vh;
}
.h-topBar{
  height: 56px;
}
.max-h-48{
  max-height: 12rem;
}
.max-h-\[1000px\]{
  max-height: 1000px;
}
.max-h-\[1024px\]{
  max-height: 1024px;
}
.max-h-\[107px\]{
  max-height: 107px;
}
.max-h-\[16px\]{
  max-height: 16px;
}
.max-h-\[204px\]{
  max-height: 204px;
}
.max-h-\[275px\]{
  max-height: 275px;
}
.max-h-\[300px\]{
  max-height: 300px;
}
.max-h-\[350px\]{
  max-height: 350px;
}
.max-h-\[400px\]{
  max-height: 400px;
}
.max-h-\[456px\]{
  max-height: 456px;
}
.max-h-\[600px\]{
  max-height: 600px;
}
.max-h-\[64px\]{
  max-height: 64px;
}
.max-h-\[685px\]{
  max-height: 685px;
}
.max-h-\[80vh\]{
  max-height: 80vh;
}
.max-h-\[90vh\]{
  max-height: 90vh;
}
.max-h-\[95vh\]{
  max-height: 95vh;
}
.max-h-\[calc\(100dvh-56px\)\]{
  max-height: calc(100dvh - 56px);
}
.max-h-\[calc\(400px-56px\)\]{
  max-height: calc(400px - 56px);
}
.max-h-screen{
  max-height: 100vh;
}
.min-h-2{
  min-height: 0.5rem;
}
.min-h-60{
  min-height: 15rem;
}
.min-h-\[100vh\]{
  min-height: 100vh;
}
.min-h-\[107px\]{
  min-height: 107px;
}
.min-h-\[148px\]{
  min-height: 148px;
}
.min-h-\[155px\]{
  min-height: 155px;
}
.min-h-\[1600px\]{
  min-height: 1600px;
}
.min-h-\[1px\]{
  min-height: 1px;
}
.min-h-\[200px\]{
  min-height: 200px;
}
.min-h-\[20px\]{
  min-height: 20px;
}
.min-h-\[245px\]{
  min-height: 245px;
}
.min-h-\[250px\]{
  min-height: 250px;
}
.min-h-\[270px\]{
  min-height: 270px;
}
.min-h-\[300px\]{
  min-height: 300px;
}
.min-h-\[75px\]{
  min-height: 75px;
}
.min-h-\[95vh\]{
  min-height: 95vh;
}
.min-h-\[unset\]{
  min-height: unset;
}
.min-h-body{
  min-height: calc(100vh - 56px);
}
.min-h-full{
  min-height: 100%;
}
.w-1{
  width: 0.25rem;
}
.w-1\/2{
  width: 50%;
}
.w-10{
  width: 2.5rem;
}
.w-16{
  width: 4rem;
}
.w-20{
  width: 5rem;
}
.w-24{
  width: 6rem;
}
.w-4{
  width: 1rem;
}
.w-5{
  width: 1.25rem;
}
.w-6{
  width: 1.5rem;
}
.w-72{
  width: 18rem;
}
.w-8{
  width: 2rem;
}
.w-\[100px\]{
  width: 100px;
}
.w-\[100vw\]{
  width: 100vw;
}
.w-\[10px\]{
  width: 10px;
}
.w-\[11\.8rem\]{
  width: 11.8rem;
}
.w-\[110\%\]{
  width: 110%;
}
.w-\[120px\]{
  width: 120px;
}
.w-\[124px\]{
  width: 124px;
}
.w-\[12px\]{
  width: 12px;
}
.w-\[150\%\]{
  width: 150%;
}
.w-\[150px\]{
  width: 150px;
}
.w-\[186px\]{
  width: 186px;
}
.w-\[192px\]{
  width: 192px;
}
.w-\[200px\]{
  width: 200px;
}
.w-\[21\.5px\]{
  width: 21.5px;
}
.w-\[240px\]{
  width: 240px;
}
.w-\[300px\]{
  width: 300px;
}
.w-\[308px\]{
  width: 308px;
}
.w-\[30px\]{
  width: 30px;
}
.w-\[30vw\]{
  width: 30vw;
}
.w-\[320\%\]{
  width: 320%;
}
.w-\[320px\]{
  width: 320px;
}
.w-\[400px\]{
  width: 400px;
}
.w-\[40px\]{
  width: 40px;
}
.w-\[40vw\]{
  width: 40vw;
}
.w-\[42px\]{
  width: 42px;
}
.w-\[489px\]{
  width: 489px;
}
.w-\[50\%\]{
  width: 50%;
}
.w-\[50vw\]{
  width: 50vw;
}
.w-\[60px\]{
  width: 60px;
}
.w-\[62px\]{
  width: 62px;
}
.w-\[700px\]{
  width: 700px;
}
.w-\[74px\]{
  width: 74px;
}
.w-\[76px\]{
  width: 76px;
}
.w-\[80\%\]{
  width: 80%;
}
.w-\[90\%\]{
  width: 90%;
}
.w-\[93px\]{
  width: 93px;
}
.w-\[calc\(100\%\+2rem\)\]{
  width: calc(100% + 2rem);
}
.w-\[calc\(100\%-13rem\)\]{
  width: calc(100% - 13rem);
}
.w-\[calc\(100\%-40px\)\]{
  width: calc(100% - 40px);
}
.w-\[calc\(100\%-7\.75rem\)\]{
  width: calc(100% - 7.75rem);
}
.w-\[calc\(50\%-0\.5rem\)\]{
  width: calc(50% - 0.5rem);
}
.w-\[inherit\]{
  width: inherit;
}
.w-\[unset\]{
  width: unset;
}
.w-auto{
  width: auto;
}
.w-full{
  width: 100%;
}
.w-screen{
  width: 100vw;
}
.min-w-0{
  min-width: 0px;
}
.min-w-24{
  min-width: 6rem;
}
.min-w-44{
  min-width: 11rem;
}
.min-w-9{
  min-width: 2.25rem;
}
.min-w-\[1400px\]{
  min-width: 1400px;
}
.min-w-\[162px\]{
  min-width: 162px;
}
.min-w-\[16px\]{
  min-width: 16px;
}
.min-w-\[176px\]{
  min-width: 176px;
}
.min-w-\[20px\]{
  min-width: 20px;
}
.min-w-\[232px\]{
  min-width: 232px;
}
.min-w-\[240px\]{
  min-width: 240px;
}
.min-w-\[250px\]{
  min-width: 250px;
}
.min-w-\[300px\]{
  min-width: 300px;
}
.min-w-\[400px\]{
  min-width: 400px;
}
.min-w-\[50vw\]{
  min-width: 50vw;
}
.min-w-\[60px\]{
  min-width: 60px;
}
.min-w-\[768px\]{
  min-width: 768px;
}
.min-w-\[calc\(100\%-4rem\)\]{
  min-width: calc(100% - 4rem);
}
.min-w-\[min\(90vw\2c 440px\)\]{
  min-width: min(90vw,440px);
}
.min-w-full{
  min-width: 100%;
}
.max-w-80{
  max-width: 20rem;
}
.max-w-96{
  max-width: 24rem;
}
.max-w-\[100px\]{
  max-width: 100px;
}
.max-w-\[100vw\]{
  max-width: 100vw;
}
.max-w-\[1300px\]{
  max-width: 1300px;
}
.max-w-\[1305px\]{
  max-width: 1305px;
}
.max-w-\[150\%\]{
  max-width: 150%;
}
.max-w-\[150px\]{
  max-width: 150px;
}
.max-w-\[160px\]{
  max-width: 160px;
}
.max-w-\[180px\]{
  max-width: 180px;
}
.max-w-\[192px\]{
  max-width: 192px;
}
.max-w-\[195px\]{
  max-width: 195px;
}
.max-w-\[240px\]{
  max-width: 240px;
}
.max-w-\[250px\]{
  max-width: 250px;
}
.max-w-\[280px\]{
  max-width: 280px;
}
.max-w-\[297px\]{
  max-width: 297px;
}
.max-w-\[300px\]{
  max-width: 300px;
}
.max-w-\[320px\]{
  max-width: 320px;
}
.max-w-\[330px\]{
  max-width: 330px;
}
.max-w-\[336px\]{
  max-width: 336px;
}
.max-w-\[340px\]{
  max-width: 340px;
}
.max-w-\[344px\]{
  max-width: 344px;
}
.max-w-\[346px\]{
  max-width: 346px;
}
.max-w-\[350px\]{
  max-width: 350px;
}
.max-w-\[384px\]{
  max-width: 384px;
}
.max-w-\[400px\]{
  max-width: 400px;
}
.max-w-\[413px\]{
  max-width: 413px;
}
.max-w-\[448px\]{
  max-width: 448px;
}
.max-w-\[450px\]{
  max-width: 450px;
}
.max-w-\[452px\]{
  max-width: 452px;
}
.max-w-\[50\%\]{
  max-width: 50%;
}
.max-w-\[550px\]{
  max-width: 550px;
}
.max-w-\[600px\]{
  max-width: 600px;
}
.max-w-\[740px\]{
  max-width: 740px;
}
.max-w-\[80\%\]{
  max-width: 80%;
}
.max-w-\[800px\]{
  max-width: 800px;
}
.max-w-\[80vw\]{
  max-width: 80vw;
}
.max-w-\[90\%\]{
  max-width: 90%;
}
.max-w-\[90vw\]{
  max-width: 90vw;
}
.max-w-\[calc\(50\%-1\.5rem\)\]{
  max-width: calc(50% - 1.5rem);
}
.max-w-\[min\(624px\2c _80vw\)\]{
  max-width: min(624px, 80vw);
}
.max-w-full{
  max-width: 100%;
}
.max-w-maxContent{
  max-width: 1440px;
}
.max-w-screen-xlarge{
  max-width: 1440px;
}
.flex-1{
  flex: 1 1 0%;
}
.flex-shrink{
  flex-shrink: 1;
}
.flex-shrink-0{
  flex-shrink: 0;
}
.shrink-0{
  flex-shrink: 0;
}
.flex-grow{
  flex-grow: 1;
}
.flex-grow-\[3\]{
  flex-grow: 3;
}
.grow{
  flex-grow: 1;
}
.grow-0{
  flex-grow: 0;
}
.basis-0{
  flex-basis: 0px;
}
.basis-full{
  flex-basis: 100%;
}
.border-collapse{
  border-collapse: collapse;
}
.origin-bottom{
  transform-origin: bottom;
}
.origin-center{
  transform-origin: center;
}
.origin-top-right{
  transform-origin: top right;
}
.-translate-x-1\/2{
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-y-72{
  --tw-translate-y: -18rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-\[-2rem\]{
  --tw-translate-x: -2rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-\[17px\]{
  --tw-translate-x: 17px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-\[308px\]{
  --tw-translate-x: 308px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-0{
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-11{
  --tw-translate-y: 2.75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-4{
  --tw-translate-y: 1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-5{
  --tw-translate-y: 1.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-\[-100\%\]{
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-\[-130\%\]{
  --tw-translate-y: -130%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-\[-15\%\]{
  --tw-translate-y: -15%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-\[-50\%\]{
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-\[110\%\]{
  --tw-translate-y: 110%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-\[99px\]{
  --tw-translate-y: 99px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-rotate-45{
  --tw-rotate: -45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-rotate-6{
  --tw-rotate: -6deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-rotate-90{
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-rotate-\[5deg\]{
  --tw-rotate: -5deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-180{
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-90{
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-\[24deg\]{
  --tw-rotate: 24deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-\[270deg\]{
  --tw-rotate: 270deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-\[5deg\]{
  --tw-rotate: 5deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-0{
  --tw-scale-x: 0;
  --tw-scale-y: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-110{
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-150{
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-\[0\.001\]{
  --tw-scale-x: 0.001;
  --tw-scale-y: 0.001;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-\[0\.7\]{
  --tw-scale-x: 0.7;
  --tw-scale-y: 0.7;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-\[1\.6\]{
  --tw-scale-x: 1.6;
  --tw-scale-y: 1.6;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-\[3\]{
  --tw-scale-x: 3;
  --tw-scale-y: 3;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-x-\[-1\]{
  --tw-scale-x: -1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.transform{
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.transform-gpu{
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.animate-\[counter_1s_ease-out_forwards\]{
  animation: counter 1s ease-out forwards;
}
@keyframes ringPulse{
  0%{
    opacity: 1;
    transform: scale(1);
  }
  100%{
    opacity: 0.0;
    transform: scale(3);
  }
}
.animate-\[ringPulse_2s_infinite\]{
  animation: ringPulse 2s infinite;
}
.cursor-\[inherit\]{
  cursor: inherit;
}
.cursor-default{
  cursor: default;
}
.cursor-pointer{
  cursor: pointer;
}
.touch-none{
  touch-action: none;
}
.select-none{
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.select-all{
  -webkit-user-select: all;
     -moz-user-select: all;
          user-select: all;
}
.resize{
  resize: both;
}
.snap-x{
  scroll-snap-type: x var(--tw-scroll-snap-strictness);
}
.snap-mandatory{
  --tw-scroll-snap-strictness: mandatory;
}
.snap-center{
  scroll-snap-align: center;
}
.list-disc{
  list-style-type: disc;
}
.appearance-none{
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.auto-rows-\[minmax\(mincontent\2c _maxcontent\)\]{
  grid-auto-rows: minmax(mincontent, maxcontent);
}
.grid-cols-1{
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.grid-cols-2{
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-cols-3{
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid-cols-4{
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
.grid-cols-5{
  grid-template-columns: repeat(5, minmax(0, 1fr));
}
.grid-cols-6{
  grid-template-columns: repeat(6, minmax(0, 1fr));
}
.grid-cols-\[0fr\]{
  grid-template-columns: 0fr;
}
.grid-cols-\[150px\2c 1fr\]{
  grid-template-columns: 150px 1fr;
}
.grid-cols-\[1fr\]{
  grid-template-columns: 1fr;
}
.grid-cols-\[1fr_1fr\]{
  grid-template-columns: 1fr 1fr;
}
.grid-cols-\[1fr_minmax\(max-content\2c _1fr\)\]{
  grid-template-columns: 1fr minmax(max-content, 1fr);
}
.grid-cols-\[repeat\(2\2c _calc\(50\%_-_0\.625rem\/2\)\)\]{
  grid-template-columns: repeat(2, calc(50% - 0.625rem / 2));
}
.grid-cols-\[repeat\(auto-fill\2c 60px\)\]{
  grid-template-columns: repeat(auto-fill,60px);
}
.grid-rows-3{
  grid-template-rows: repeat(3, minmax(0, 1fr));
}
.grid-rows-\[0fr\]{
  grid-template-rows: 0fr;
}
.grid-rows-\[1fr\]{
  grid-template-rows: 1fr;
}
.flex-row{
  flex-direction: row;
}
.flex-row-reverse{
  flex-direction: row-reverse;
}
.flex-col{
  flex-direction: column;
}
.flex-col-reverse{
  flex-direction: column-reverse;
}
.flex-wrap{
  flex-wrap: wrap;
}
.flex-nowrap{
  flex-wrap: nowrap;
}
.content-start{
  align-content: flex-start;
}
.content-end{
  align-content: flex-end;
}
.items-start{
  align-items: flex-start;
}
.items-end{
  align-items: flex-end;
}
.items-center{
  align-items: center;
}
.items-stretch{
  align-items: stretch;
}
.justify-start{
  justify-content: flex-start;
}
.justify-end{
  justify-content: flex-end;
}
.\!justify-center{
  justify-content: center !important;
}
.justify-center{
  justify-content: center;
}
.justify-between{
  justify-content: space-between;
}
.justify-around{
  justify-content: space-around;
}
.justify-evenly{
  justify-content: space-evenly;
}
.gap-0{
  gap: 0px;
}
.gap-1{
  gap: 0.25rem;
}
.gap-10{
  gap: 2.5rem;
}
.gap-16{
  gap: 4rem;
}
.gap-2{
  gap: 0.5rem;
}
.gap-20{
  gap: 5rem;
}
.gap-24{
  gap: 6rem;
}
.gap-3{
  gap: 0.75rem;
}
.gap-4{
  gap: 1rem;
}
.gap-5{
  gap: 1.25rem;
}
.gap-6{
  gap: 1.5rem;
}
.gap-8{
  gap: 2rem;
}
.gap-\[0\.11rem\]{
  gap: 0.11rem;
}
.gap-\[0\.125rem\]{
  gap: 0.125rem;
}
.gap-\[0\.25rem\]{
  gap: 0.25rem;
}
.gap-\[0\.3rem\]{
  gap: 0.3rem;
}
.gap-\[0\.625rem\]{
  gap: 0.625rem;
}
.gap-\[0\.8rem\]{
  gap: 0.8rem;
}
.gap-\[1\.2rem\]{
  gap: 1.2rem;
}
.gap-\[10px\]{
  gap: 10px;
}
.gap-\[2px\]{
  gap: 2px;
}
.gap-\[2rem\]{
  gap: 2rem;
}
.gap-\[3px\]{
  gap: 3px;
}
.gap-\[6px\]{
  gap: 6px;
}
.gap-x-3{
  -moz-column-gap: 0.75rem;
       column-gap: 0.75rem;
}
.gap-x-4{
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.gap-x-6{
  -moz-column-gap: 1.5rem;
       column-gap: 1.5rem;
}
.gap-y-12{
  row-gap: 3rem;
}
.gap-y-4{
  row-gap: 1rem;
}
.gap-y-6{
  row-gap: 1.5rem;
}
.gap-y-8{
  row-gap: 2rem;
}
.gap-y-\[14px\]{
  row-gap: 14px;
}
.self-start{
  align-self: flex-start;
}
.self-end{
  align-self: flex-end;
}
.overflow-auto{
  overflow: auto;
}
.overflow-hidden{
  overflow: hidden;
}
.overflow-clip{
  overflow: clip;
}
.overflow-visible{
  overflow: visible;
}
.overflow-x-auto{
  overflow-x: auto;
}
.overflow-y-auto{
  overflow-y: auto;
}
.overflow-x-hidden{
  overflow-x: hidden;
}
.overflow-y-hidden{
  overflow-y: hidden;
}
.overflow-x-scroll{
  overflow-x: scroll;
}
.overflow-y-scroll{
  overflow-y: scroll;
}
.truncate{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.overflow-ellipsis{
  text-overflow: ellipsis;
}
.text-ellipsis{
  text-overflow: ellipsis;
}
.whitespace-nowrap{
  white-space: nowrap;
}
.text-wrap{
  text-wrap: wrap;
}
.text-nowrap{
  text-wrap: nowrap;
}
.break-words{
  overflow-wrap: break-word;
}
.break-all{
  word-break: break-all;
}
.break-keep{
  word-break: keep-all;
}
.rounded{
  border-radius: 0.25rem;
}
.rounded-2xl{
  border-radius: 1rem;
}
.rounded-3xl{
  border-radius: 1.5rem;
}
.rounded-\[0\.2rem\]{
  border-radius: 0.2rem;
}
.rounded-\[10px\]{
  border-radius: 10px;
}
.rounded-\[50\%\]{
  border-radius: 50%;
}
.rounded-full{
  border-radius: 9999px;
}
.rounded-lg{
  border-radius: 0.5rem;
}
.rounded-md{
  border-radius: 0.375rem;
}
.rounded-xl{
  border-radius: 0.75rem;
}
.rounded-r-none{
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.rounded-r-xl{
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}
.rounded-t-lg{
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.rounded-t-none{
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.rounded-bl-lg{
  border-bottom-left-radius: 0.5rem;
}
.rounded-br-lg{
  border-bottom-right-radius: 0.5rem;
}
.rounded-tl-none{
  border-top-left-radius: 0px;
}
.border{
  border-width: 1px;
}
.border-0{
  border-width: 0px;
}
.border-2{
  border-width: 2px;
}
.border-4{
  border-width: 4px;
}
.border-8{
  border-width: 8px;
}
.border-\[1px\]{
  border-width: 1px;
}
.border-\[3px\]{
  border-width: 3px;
}
.border-x-\[1px\]{
  border-left-width: 1px;
  border-right-width: 1px;
}
.border-y-\[0px\]{
  border-top-width: 0px;
  border-bottom-width: 0px;
}
.border-b-0{
  border-bottom-width: 0px;
}
.border-b-\[1px\]{
  border-bottom-width: 1px;
}
.border-b-\[2px\]{
  border-bottom-width: 2px;
}
.border-b-\[4px\]{
  border-bottom-width: 4px;
}
.border-l-0{
  border-left-width: 0px;
}
.border-r-0{
  border-right-width: 0px;
}
.border-t{
  border-top-width: 1px;
}
.border-t-\[1px\]{
  border-top-width: 1px;
}
.border-solid{
  border-style: solid;
}
.border-none{
  border-style: none;
}
.border-accents-green-primary{
  --tw-border-opacity: 1;
  border-color: rgb(9 181 132 / var(--tw-border-opacity));
}
.border-chat-header{
  --tw-border-opacity: 1;
  border-color: rgb(52 53 64 / var(--tw-border-opacity));
}
.border-greyscale-dark{
  --tw-border-opacity: 1;
  border-color: rgb(69 70 84 / var(--tw-border-opacity));
}
.border-greyscale-hiberBlack{
  --tw-border-opacity: 1;
  border-color: rgb(27 28 32 / var(--tw-border-opacity));
}
.border-greyscale-lightGrey{
  --tw-border-opacity: 1;
  border-color: rgb(167 169 175 / var(--tw-border-opacity));
}
.border-greyscale-mediumGrey{
  --tw-border-opacity: 1;
  border-color: rgb(144 147 152 / var(--tw-border-opacity));
}
.border-greyscale-veryDark{
  --tw-border-opacity: 1;
  border-color: rgb(52 53 64 / var(--tw-border-opacity));
}
.border-greyscale-white{
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}
.border-transparent{
  border-color: transparent;
}
.border-t-greyscale-dark{
  --tw-border-opacity: 1;
  border-top-color: rgb(69 70 84 / var(--tw-border-opacity));
}
.bg-\[\#0f101f\]{
  --tw-bg-opacity: 1;
  background-color: rgb(15 16 31 / var(--tw-bg-opacity));
}
.bg-\[\#1B222C\]{
  --tw-bg-opacity: 1;
  background-color: rgb(27 34 44 / var(--tw-bg-opacity));
}
.bg-\[\#24252b\]{
  --tw-bg-opacity: 1;
  background-color: rgb(36 37 43 / var(--tw-bg-opacity));
}
.bg-\[\#343540\]{
  --tw-bg-opacity: 1;
  background-color: rgb(52 53 64 / var(--tw-bg-opacity));
}
.bg-\[rgb\(0\2c 0\2c 0\)\]\/50{
  background-color: rgb(0 0 0 / 0.5);
}
.bg-\[rgba\(27\2c 28\2c 32\2c 0\.8\)\]{
  background-color: rgba(27,28,32,0.8);
}
.bg-\[rgba\(52\2c 54\2c 60\2c 0\.25\)\]{
  background-color: rgba(52,54,60,0.25);
}
.bg-accents-green-primary{
  --tw-bg-opacity: 1;
  background-color: rgb(9 181 132 / var(--tw-bg-opacity));
}
.bg-accents-purple-primary{
  --tw-bg-opacity: 1;
  background-color: rgb(100 36 204 / var(--tw-bg-opacity));
}
.bg-card-background{
  --tw-bg-opacity: 1;
  background-color: rgb(41 42 49 / var(--tw-bg-opacity));
}
.bg-chat-header{
  --tw-bg-opacity: 1;
  background-color: rgb(52 53 64 / var(--tw-bg-opacity));
}
.bg-chat-message-me{
  --tw-bg-opacity: 1;
  background-color: rgb(100 36 204 / var(--tw-bg-opacity));
}
.bg-chat-message-other{
  --tw-bg-opacity: 1;
  background-color: rgb(52 53 64 / var(--tw-bg-opacity));
}
.bg-dialog-backdrop{
  background-color: #1B1C20B8;
}
.bg-dialog-background{
  --tw-bg-opacity: 1;
  background-color: rgb(41 42 49 / var(--tw-bg-opacity));
}
.bg-greyscale-almostBlack{
  --tw-bg-opacity: 1;
  background-color: rgb(41 42 49 / var(--tw-bg-opacity));
}
.bg-greyscale-almostBlack\/40{
  background-color: rgb(41 42 49 / 0.4);
}
.bg-greyscale-dark{
  --tw-bg-opacity: 1;
  background-color: rgb(69 70 84 / var(--tw-bg-opacity));
}
.bg-greyscale-hiberBlack{
  --tw-bg-opacity: 1;
  background-color: rgb(27 28 32 / var(--tw-bg-opacity));
}
.bg-greyscale-hiberBlack\/30{
  background-color: rgb(27 28 32 / 0.3);
}
.bg-greyscale-hiberBlack\/50{
  background-color: rgb(27 28 32 / 0.5);
}
.bg-greyscale-lightGrey{
  --tw-bg-opacity: 1;
  background-color: rgb(167 169 175 / var(--tw-bg-opacity));
}
.bg-greyscale-mediumGrey{
  --tw-bg-opacity: 1;
  background-color: rgb(144 147 152 / var(--tw-bg-opacity));
}
.bg-greyscale-veryDark{
  --tw-bg-opacity: 1;
  background-color: rgb(52 53 64 / var(--tw-bg-opacity));
}
.bg-greyscale-veryLightGrey{
  --tw-bg-opacity: 1;
  background-color: rgb(233 233 236 / var(--tw-bg-opacity));
}
.bg-navigation-background{
  --tw-bg-opacity: 1;
  background-color: rgb(41 42 49 / var(--tw-bg-opacity));
}
.bg-notification-background{
  --tw-bg-opacity: 1;
  background-color: rgb(69 70 84 / var(--tw-bg-opacity));
}
.bg-pill-default{
  --tw-bg-opacity: 1;
  background-color: rgb(224 43 54 / var(--tw-bg-opacity));
}
.bg-primary-main{
  --tw-bg-opacity: 1;
  background-color: rgb(224 43 54 / var(--tw-bg-opacity));
}
.bg-rarity-common-main{
  --tw-bg-opacity: 1;
  background-color: rgb(69 70 84 / var(--tw-bg-opacity));
}
.bg-rarity-epic-main{
  --tw-bg-opacity: 1;
  background-color: rgb(97 58 159 / var(--tw-bg-opacity));
}
.bg-rarity-legendary-main{
  --tw-bg-opacity: 1;
  background-color: rgb(155 125 48 / var(--tw-bg-opacity));
}
.bg-rarity-rare-main{
  --tw-bg-opacity: 1;
  background-color: rgb(42 95 158 / var(--tw-bg-opacity));
}
.bg-rarity-uncommon-main{
  --tw-bg-opacity: 1;
  background-color: rgb(54 152 81 / var(--tw-bg-opacity));
}
.bg-status-online{
  --tw-bg-opacity: 1;
  background-color: rgb(9 181 132 / var(--tw-bg-opacity));
}
.bg-text-error{
  --tw-bg-opacity: 1;
  background-color: rgb(224 43 54 / var(--tw-bg-opacity));
}
.bg-text-info{
  --tw-bg-opacity: 1;
  background-color: rgb(167 169 175 / var(--tw-bg-opacity));
}
.bg-transparent{
  background-color: transparent;
}
.bg-opacity-0{
  --tw-bg-opacity: 0;
}
.bg-opacity-100{
  --tw-bg-opacity: 1;
}
.bg-opacity-30{
  --tw-bg-opacity: 0.3;
}
.bg-opacity-70{
  --tw-bg-opacity: 0.7;
}
.bg-opacity-80{
  --tw-bg-opacity: 0.8;
}
.bg-\[radial-gradient\(36\.09\%_50\%_at_50\%_50\%\2c _\#FFFFFF33_0\%\2c _rgba\(39\2c 39\2c 39\2c 0\)_100\%\)\]{
  background-image: radial-gradient(36.09% 50% at 50% 50%, #FFFFFF33 0%, rgba(39,39,39,0) 100%);
}
.bg-\[radial-gradient\(50\%_50\%_at_50\%_50\%\2c rgba\(27\2c 28\2c 32\)_0\%\2c rgba\(27\2c 28\2c 32\2c 0\.78\)_64\%\2c rgba\(27\2c 28\2c 32\2c 0\.55\)_80\%\2c rgba\(27\2c 28\2c 32\2c 0\)_100\%\)\]{
  background-image: radial-gradient(50% 50% at 50% 50%,rgba(27,28,32) 0%,rgba(27,28,32,0.78) 64%,rgba(27,28,32,0.55) 80%,rgba(27,28,32,0) 100%);
}
.bg-\[radial-gradient\(ellipse_at_center\2c _var\(--tw-gradient-stops\)\)\]{
  background-image: radial-gradient(ellipse at center, var(--tw-gradient-stops));
}
.bg-gradient-to-b{
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}
.bg-gradient-to-br{
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
}
.bg-gradient-to-l{
  background-image: linear-gradient(to left, var(--tw-gradient-stops));
}
.bg-gradient-to-r{
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}
.bg-gradient-to-t{
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
}
.bg-none{
  background-image: none;
}
.from-\[\#BD3FD1\]{
  --tw-gradient-from: #BD3FD1 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(189 63 209 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-\[rgba\(0\2c 0\2c 0\2c 0\.4\)\]{
  --tw-gradient-from: rgba(0,0,0,0.4) var(--tw-gradient-from-position);
  --tw-gradient-to: rgba(0, 0, 0, 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-accents-purple-primary{
  --tw-gradient-from: #6424CC var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(100 36 204 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-greyscale-almostBlack{
  --tw-gradient-from: #292A31 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(41 42 49 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-greyscale-almostBlack\/95{
  --tw-gradient-from: rgb(41 42 49 / 0.95) var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(41 42 49 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-greyscale-hiberBlack{
  --tw-gradient-from: #1B1C20 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(27 28 32 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-greyscale-hiberBlack\/0{
  --tw-gradient-from: rgb(27 28 32 / 0) var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(27 28 32 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-greyscale-hiberBlack\/100{
  --tw-gradient-from: rgb(27 28 32 / 1) var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(27 28 32 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-greyscale-hiberBlack\/50{
  --tw-gradient-from: rgb(27 28 32 / 0.5) var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(27 28 32 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-greyscale-veryDark{
  --tw-gradient-from: #343540 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(52 53 64 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-0\%{
  --tw-gradient-from-position: 0%;
}
.from-10\%{
  --tw-gradient-from-position: 10%;
}
.from-20\%{
  --tw-gradient-from-position: 20%;
}
.from-30\%{
  --tw-gradient-from-position: 30%;
}
.from-35\%{
  --tw-gradient-from-position: 35%;
}
.from-45\%{
  --tw-gradient-from-position: 45%;
}
.from-50\%{
  --tw-gradient-from-position: 50%;
}
.from-\[-10\%\]{
  --tw-gradient-from-position: -10%;
}
.from-\[-20\%\]{
  --tw-gradient-from-position: -20%;
}
.from-\[-3\%\]{
  --tw-gradient-from-position: -3%;
}
.via-greyscale-almostBlack{
  --tw-gradient-to: rgb(41 42 49 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #292A31 var(--tw-gradient-via-position), var(--tw-gradient-to);
}
.via-greyscale-almostBlack\/20{
  --tw-gradient-to: rgb(41 42 49 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), rgb(41 42 49 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to);
}
.via-greyscale-almostBlack\/60{
  --tw-gradient-to: rgb(41 42 49 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), rgb(41 42 49 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to);
}
.via-greyscale-almostBlack\/70{
  --tw-gradient-to: rgb(41 42 49 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), rgb(41 42 49 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to);
}
.via-greyscale-dark{
  --tw-gradient-to: rgb(69 70 84 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #454654 var(--tw-gradient-via-position), var(--tw-gradient-to);
}
.via-greyscale-hiberBlack{
  --tw-gradient-to: rgb(27 28 32 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #1B1C20 var(--tw-gradient-via-position), var(--tw-gradient-to);
}
.via-greyscale-hiberBlack\/40{
  --tw-gradient-to: rgb(27 28 32 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), rgb(27 28 32 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to);
}
.via-greyscale-hiberBlack\/85{
  --tw-gradient-to: rgb(27 28 32 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), rgb(27 28 32 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to);
}
.via-greyscale-veryDark{
  --tw-gradient-to: rgb(52 53 64 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #343540 var(--tw-gradient-via-position), var(--tw-gradient-to);
}
.via-0\%{
  --tw-gradient-via-position: 0%;
}
.via-10\%{
  --tw-gradient-via-position: 10%;
}
.via-20\%{
  --tw-gradient-via-position: 20%;
}
.via-30\%{
  --tw-gradient-via-position: 30%;
}
.via-35\%{
  --tw-gradient-via-position: 35%;
}
.via-40\%{
  --tw-gradient-via-position: 40%;
}
.via-50\%{
  --tw-gradient-via-position: 50%;
}
.via-60\%{
  --tw-gradient-via-position: 60%;
}
.via-70\%{
  --tw-gradient-via-position: 70%;
}
.via-75\%{
  --tw-gradient-via-position: 75%;
}
.via-80\%{
  --tw-gradient-via-position: 80%;
}
.to-\[\#814CC7\]{
  --tw-gradient-to: #814CC7 var(--tw-gradient-to-position);
}
.to-\[rgba\(255\2c 255\2c 255\2c 0\)\]{
  --tw-gradient-to: rgba(255,255,255,0) var(--tw-gradient-to-position);
}
.to-accents-green-primary{
  --tw-gradient-to: #09B584 var(--tw-gradient-to-position);
}
.to-greyscale-almostBlack{
  --tw-gradient-to: #292A31 var(--tw-gradient-to-position);
}
.to-greyscale-almostBlack\/0{
  --tw-gradient-to: rgb(41 42 49 / 0) var(--tw-gradient-to-position);
}
.to-greyscale-almostBlack\/15{
  --tw-gradient-to: rgb(41 42 49 / 0.15) var(--tw-gradient-to-position);
}
.to-greyscale-almostBlack\/30{
  --tw-gradient-to: rgb(41 42 49 / 0.3) var(--tw-gradient-to-position);
}
.to-greyscale-hiberBlack{
  --tw-gradient-to: #1B1C20 var(--tw-gradient-to-position);
}
.to-greyscale-veryDark{
  --tw-gradient-to: #343540 var(--tw-gradient-to-position);
}
.to-transparent{
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
}
.to-100\%{
  --tw-gradient-to-position: 100%;
}
.to-40\%{
  --tw-gradient-to-position: 40%;
}
.to-70\%{
  --tw-gradient-to-position: 70%;
}
.to-75\%{
  --tw-gradient-to-position: 75%;
}
.to-80\%{
  --tw-gradient-to-position: 80%;
}
.to-\[110\%\]{
  --tw-gradient-to-position: 110%;
}
.to-\[85\%\]{
  --tw-gradient-to-position: 85%;
}
.box-decoration-clone{
  -webkit-box-decoration-break: clone;
          box-decoration-break: clone;
}
.bg-cover{
  background-size: cover;
}
.bg-clip-content{
  background-clip: content-box;
}
.bg-center{
  background-position: center;
}
.bg-no-repeat{
  background-repeat: no-repeat;
}
.stroke-greyscale-dark{
  stroke: #454654;
}
.object-contain{
  -o-object-fit: contain;
     object-fit: contain;
}
.object-cover{
  -o-object-fit: cover;
     object-fit: cover;
}
.object-fill{
  -o-object-fit: fill;
     object-fit: fill;
}
.object-\[0\%_0\%\]{
  -o-object-position: 0% 0%;
     object-position: 0% 0%;
}
.object-\[0\%_100\%\]{
  -o-object-position: 0% 100%;
     object-position: 0% 100%;
}
.object-\[25\%_0\%\]{
  -o-object-position: 25% 0%;
     object-position: 25% 0%;
}
.object-\[left_85\%\]{
  -o-object-position: left 85%;
     object-position: left 85%;
}
.object-center{
  -o-object-position: center;
     object-position: center;
}
.object-top{
  -o-object-position: top;
     object-position: top;
}
.p-0{
  padding: 0px;
}
.p-1{
  padding: 0.25rem;
}
.p-10{
  padding: 2.5rem;
}
.p-12{
  padding: 3rem;
}
.p-2{
  padding: 0.5rem;
}
.p-3{
  padding: 0.75rem;
}
.p-4{
  padding: 1rem;
}
.p-5{
  padding: 1.25rem;
}
.p-6{
  padding: 1.5rem;
}
.p-8{
  padding: 2rem;
}
.p-\[0\.73rem\]{
  padding: 0.73rem;
}
.p-\[10px\]{
  padding: 10px;
}
.p-\[24px\]{
  padding: 24px;
}
.px-0{
  padding-left: 0px;
  padding-right: 0px;
}
.px-1{
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-10{
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.px-12{
  padding-left: 3rem;
  padding-right: 3rem;
}
.px-16{
  padding-left: 4rem;
  padding-right: 4rem;
}
.px-2{
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3{
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4{
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-6{
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.px-7{
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}
.px-8{
  padding-left: 2rem;
  padding-right: 2rem;
}
.px-\[0\.275rem\]{
  padding-left: 0.275rem;
  padding-right: 0.275rem;
}
.px-\[1\.75rem\]{
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}
.px-\[18px\]{
  padding-left: 18px;
  padding-right: 18px;
}
.px-\[4px\]{
  padding-left: 4px;
  padding-right: 4px;
}
.py-0{
  padding-top: 0px;
  padding-bottom: 0px;
}
.py-0\.5{
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}
.py-1{
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-10{
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.py-12{
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.py-2{
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-24{
  padding-top: 6rem;
  padding-bottom: 6rem;
}
.py-3{
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4{
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.py-6{
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.py-8{
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.py-\[0\.125rem\]{
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}
.py-\[0\.175rem\]{
  padding-top: 0.175rem;
  padding-bottom: 0.175rem;
}
.py-\[0\.375rem\]{
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}
.py-\[0\.6rem\]{
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}
.py-\[1\.5rem\]{
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.py-\[2px\]{
  padding-top: 2px;
  padding-bottom: 2px;
}
.py-\[60px\]{
  padding-top: 60px;
  padding-bottom: 60px;
}
.pb-0{
  padding-bottom: 0px;
}
.pb-1{
  padding-bottom: 0.25rem;
}
.pb-10{
  padding-bottom: 2.5rem;
}
.pb-12{
  padding-bottom: 3rem;
}
.pb-14{
  padding-bottom: 3.5rem;
}
.pb-16{
  padding-bottom: 4rem;
}
.pb-2{
  padding-bottom: 0.5rem;
}
.pb-20{
  padding-bottom: 5rem;
}
.pb-4{
  padding-bottom: 1rem;
}
.pb-5{
  padding-bottom: 1.25rem;
}
.pb-6{
  padding-bottom: 1.5rem;
}
.pb-8{
  padding-bottom: 2rem;
}
.pb-\[0\.4rem\]{
  padding-bottom: 0.4rem;
}
.pb-\[0\.6rem\]{
  padding-bottom: 0.6rem;
}
.pb-\[2px\]{
  padding-bottom: 2px;
}
.pb-\[3vh\]{
  padding-bottom: 3vh;
}
.pb-\[4\.25rem\]{
  padding-bottom: 4.25rem;
}
.pb-\[5px\]{
  padding-bottom: 5px;
}
.pl-0{
  padding-left: 0px;
}
.pl-1{
  padding-left: 0.25rem;
}
.pl-2{
  padding-left: 0.5rem;
}
.pl-2\.5{
  padding-left: 0.625rem;
}
.pl-3{
  padding-left: 0.75rem;
}
.pl-4{
  padding-left: 1rem;
}
.pl-5{
  padding-left: 1.25rem;
}
.pl-6{
  padding-left: 1.5rem;
}
.pl-7{
  padding-left: 1.75rem;
}
.pl-\[12px\]{
  padding-left: 12px;
}
.pl-\[21px\]{
  padding-left: 21px;
}
.pl-\[calc\(1\.25rem\+52px\)\]{
  padding-left: calc(1.25rem + 52px);
}
.pr-0{
  padding-right: 0px;
}
.pr-1{
  padding-right: 0.25rem;
}
.pr-10{
  padding-right: 2.5rem;
}
.pr-16{
  padding-right: 4rem;
}
.pr-2{
  padding-right: 0.5rem;
}
.pr-3{
  padding-right: 0.75rem;
}
.pr-4{
  padding-right: 1rem;
}
.pr-6{
  padding-right: 1.5rem;
}
.pr-8{
  padding-right: 2rem;
}
.pr-\[20px\]{
  padding-right: 20px;
}
.pr-\[6px\]{
  padding-right: 6px;
}
.pt-1{
  padding-top: 0.25rem;
}
.pt-10{
  padding-top: 2.5rem;
}
.pt-11{
  padding-top: 2.75rem;
}
.pt-12{
  padding-top: 3rem;
}
.pt-14{
  padding-top: 3.5rem;
}
.pt-2{
  padding-top: 0.5rem;
}
.pt-20{
  padding-top: 5rem;
}
.pt-24{
  padding-top: 6rem;
}
.pt-3{
  padding-top: 0.75rem;
}
.pt-4{
  padding-top: 1rem;
}
.pt-5{
  padding-top: 1.25rem;
}
.pt-6{
  padding-top: 1.5rem;
}
.pt-8{
  padding-top: 2rem;
}
.pt-\[1\.75rem\]{
  padding-top: 1.75rem;
}
.pt-\[20vh\]{
  padding-top: 20vh;
}
.pt-\[30vh\]{
  padding-top: 30vh;
}
.text-left{
  text-align: left;
}
.text-center{
  text-align: center;
}
.align-top{
  vertical-align: top;
}
.align-middle{
  vertical-align: middle;
}
.font-\[monospace\]{
  font-family: monospace;
}
.text-\[12px\]{
  font-size: 12px;
}
.text-\[14px\]{
  font-size: 14px;
}
.text-\[1rem\]{
  font-size: 1rem;
}
.text-\[20px\]{
  font-size: 20px;
}
.text-button_large{
  font-size: 1rem;
  line-height: 1;
}
.text-button_medium{
  font-size: 0.875rem;
  line-height: 1;
}
.text-button_small{
  font-size: 0.75rem;
  line-height: 1;
}
.text-h1{
  font-size: clamp(2.75rem, 1.9779rem + 2.5735vw, 3.625rem);
  line-height: 1.1;
}
.text-h2{
  font-size: clamp(2rem, 1.3382rem + 2.2059vw, 2.75rem);
  line-height: 1.09;
}
.text-h3{
  font-size: clamp(1.625rem, 1.2941rem + 1.1029vw, 2rem);
  line-height: 1.25;
}
.text-h4{
  font-size: clamp(1.375rem, 1.2647rem + 0.3676vw, 1.5rem);
  line-height: 1.17;
}
.text-h5{
  font-size: 1.125rem;
  line-height: 1.11;
}
.text-h6{
  font-size: 0.875rem;
  line-height: 1.15;
}
.text-label_large{
  font-size: 0.875rem;
  line-height: 1.15;
}
.text-label_medium{
  font-size: 0.75rem;
  line-height: 1.33;
}
.text-label_small{
  font-size: 0.625rem;
  line-height: 1.6;
}
.text-label_specialSmall{
  font-size: 0.6875rem;
  line-height: 0.73;
}
.text-label_xlarge{
  font-size: 1rem;
  line-height: 1.25;
}
.text-p_large{
  font-size: clamp(1.125rem, 1.0147rem + 0.3676vw, 1.25rem);
  line-height: 1.5;
}
.text-p_medium{
  font-size: clamp(0.875rem, 0.7649rem + 0.367vw, 1rem);
  line-height: 1.5;
}
.text-p_small{
  font-size: 0.875rem;
  line-height: 1.57;
}
.font-bold{
  font-weight: 700;
}
.font-extrabold{
  font-weight: 800;
}
.font-medium{
  font-weight: 500;
}
.font-normal{
  font-weight: 400;
}
.font-semibold{
  font-weight: 600;
}
.uppercase{
  text-transform: uppercase;
}
.lowercase{
  text-transform: lowercase;
}
.capitalize{
  text-transform: capitalize;
}
.italic{
  font-style: italic;
}
.tabular-nums{
  --tw-numeric-spacing: tabular-nums;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}
.leading-3{
  line-height: .75rem;
}
.leading-4{
  line-height: 1rem;
}
.leading-\[14px\]{
  line-height: 14px;
}
.leading-\[normal\]{
  line-height: normal;
}
.leading-none{
  line-height: 1;
}
.tracking-\[-0\.01em\]{
  letter-spacing: -0.01em;
}
.tracking-\[1px\]{
  letter-spacing: 1px;
}
.text-accents-green-primary{
  --tw-text-opacity: 1;
  color: rgb(9 181 132 / var(--tw-text-opacity));
}
.text-greyscale-lightGrey{
  --tw-text-opacity: 1;
  color: rgb(167 169 175 / var(--tw-text-opacity));
}
.text-greyscale-mediumGrey{
  --tw-text-opacity: 1;
  color: rgb(144 147 152 / var(--tw-text-opacity));
}
.text-greyscale-veryLightGrey{
  --tw-text-opacity: 1;
  color: rgb(233 233 236 / var(--tw-text-opacity));
}
.text-greyscale-white{
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.text-heading-softText{
  --tw-text-opacity: 1;
  color: rgb(233 233 236 / var(--tw-text-opacity));
}
.text-input-default-text{
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.text-link-default-text{
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.text-primary-main{
  --tw-text-opacity: 1;
  color: rgb(224 43 54 / var(--tw-text-opacity));
}
.text-text-default{
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.text-text-disabled{
  --tw-text-opacity: 1;
  color: rgb(167 169 175 / var(--tw-text-opacity));
}
.text-text-error{
  --tw-text-opacity: 1;
  color: rgb(224 43 54 / var(--tw-text-opacity));
}
.text-text-info{
  --tw-text-opacity: 1;
  color: rgb(167 169 175 / var(--tw-text-opacity));
}
.text-text-success{
  --tw-text-opacity: 1;
  color: rgb(9 181 132 / var(--tw-text-opacity));
}
.text-transparent{
  color: transparent;
}
.underline{
  text-decoration-line: underline;
}
.no-underline{
  text-decoration-line: none;
}
.opacity-0{
  opacity: 0;
}
.opacity-100{
  opacity: 1;
}
.opacity-40{
  opacity: 0.4;
}
.opacity-60{
  opacity: 0.6;
}
.opacity-80{
  opacity: 0.8;
}
.opacity-90{
  opacity: 0.9;
}
.opacity-\[0\]{
  opacity: 0;
}
.shadow{
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-\[0_8px_16px_0_rgba\(27\2c 28\2c 32\2c 0\.2\)\]{
  --tw-shadow: 0 8px 16px 0 rgba(27,28,32,0.2);
  --tw-shadow-colored: 0 8px 16px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-\[0px_0\.5rem_1rem_0px_rgba\(27\2c _28\2c _32\2c _0\.4\)\]{
  --tw-shadow: 0px 0.5rem 1rem 0px rgba(27, 28, 32, 0.4);
  --tw-shadow-colored: 0px 0.5rem 1rem 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-\[rgba\(0\2c 0\2c 0\2c 0\.8\)_0px_0px_12px_0px\]{
  --tw-shadow: rgba(0,0,0,0.8) 0px 0px 12px 0px;
  --tw-shadow-colored: 0px 0px 12px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-md{
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-menu{
  --tw-shadow: 0px 4px 16px rgba(17, 18, 22, 0.3);
  --tw-shadow-colored: 0px 4px 16px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.outline-none{
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.outline{
  outline-style: solid;
}
.outline-0{
  outline-width: 0px;
}
.outline-greyscale-dark{
  outline-color: #454654;
}
.blur{
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.blur-3xl{
  --tw-blur: blur(64px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.blur-\[140px\]{
  --tw-blur: blur(140px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.blur-\[250px\]{
  --tw-blur: blur(250px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.blur-\[30px\]{
  --tw-blur: blur(30px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.blur-\[32px\]{
  --tw-blur: blur(32px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.blur-\[350px\]{
  --tw-blur: blur(350px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.blur-\[35px\]{
  --tw-blur: blur(35px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.blur-\[60px\]{
  --tw-blur: blur(60px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.blur-\[80px\]{
  --tw-blur: blur(80px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.blur-md{
  --tw-blur: blur(12px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.brightness-110{
  --tw-brightness: brightness(1.1);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.brightness-75{
  --tw-brightness: brightness(.75);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.brightness-90{
  --tw-brightness: brightness(.9);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.brightness-\[105\%\]{
  --tw-brightness: brightness(105%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.contrast-100{
  --tw-contrast: contrast(1);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.contrast-\[110\%\]{
  --tw-contrast: contrast(110%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.drop-shadow{
  --tw-drop-shadow: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.drop-shadow-\[0_0_20px_rgba\(240\2c 220\2c 122\2c 0\.5\)\]{
  --tw-drop-shadow: drop-shadow(0 0 20px rgba(240,220,122,0.5));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.drop-shadow-\[0_0_20px_rgba\(240\2c 220\2c 122\2c 1\)\]{
  --tw-drop-shadow: drop-shadow(0 0 20px rgba(240,220,122,1));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.drop-shadow-\[0_5px_5px_rgba\(0\2c 0\2c 0\2c 0\.5\)\]{
  --tw-drop-shadow: drop-shadow(0 5px 5px rgba(0,0,0,0.5));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.drop-shadow-\[2px_2px_30px_rgba\(0\2c 0\2c 0\2c 0\.91\)\]{
  --tw-drop-shadow: drop-shadow(2px 2px 30px rgba(0,0,0,0.91));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.grayscale{
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.invert{
  --tw-invert: invert(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.saturate-\[140\%\]{
  --tw-saturate: saturate(140%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.\!filter{
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}
.filter{
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.filter-none{
  filter: none;
}
.backdrop-blur-\[20px\]{
  --tw-backdrop-blur: blur(20px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
.backdrop-blur-\[8px\]{
  --tw-backdrop-blur: blur(8px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
.backdrop-filter{
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
.transition{
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-\[border-width\]{
  transition-property: border-width;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-\[max-height\]{
  transition-property: max-height;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-\[opacity\2c margin\]{
  transition-property: opacity,margin;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-\[width\2c padding\]{
  transition-property: width,padding;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-all{
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-colors{
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-opacity{
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-transform{
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.delay-200{
  transition-delay: 200ms;
}
.delay-700{
  transition-delay: 700ms;
}
.delay-\[10\]{
  transition-delay: 10;
}
.delay-\[2\.9s\]{
  transition-delay: 2.9s;
}
.delay-\[2600ms\]{
  transition-delay: 2600ms;
}
.duration-100{
  transition-duration: 100ms;
}
.duration-1000{
  transition-duration: 1000ms;
}
.duration-150{
  transition-duration: 150ms;
}
.duration-200{
  transition-duration: 200ms;
}
.duration-300{
  transition-duration: 300ms;
}
.duration-500{
  transition-duration: 500ms;
}
.duration-700{
  transition-duration: 700ms;
}
.duration-\[1000ms\]{
  transition-duration: 1000ms;
}
.duration-\[1400ms\]{
  transition-duration: 1400ms;
}
.duration-\[350ms\]{
  transition-duration: 350ms;
}
.duration-\[600ms\]{
  transition-duration: 600ms;
}
.duration-fast{
  transition-duration: 0.25s;
}
.ease-in{
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}
.ease-in-out{
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.ease-linear{
  transition-timing-function: linear;
}
.ease-out{
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.will-change-transform{
  will-change: transform;
}
.break-words {
    overflow-wrap: break-word;
    word-break: break-word;
  }
.scrollbar-hidden ::-webkit-scrollbar {
      display: none;
    }
.scrollbar-hidden{
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.thumbnail-filter {
    filter: saturate(140%) contrast(110%) brightness(105%);
  }
.bottom-gradient {
    -webkit-mask-image: linear-gradient(
      to bottom,
      transparent 0%,
      black 16px,
      black calc(100% - 16px),
      transparent 100%
    );
    mask-image: linear-gradient(to bottom, transparent 0%, black 16px, black calc(100% -16px), transparent 100%);
  }
.\[animation-delay\:0\.0s\]{
  animation-delay: 0.0s;
}
.\[animation-delay\:0\.6s\]{
  animation-delay: 0.6s;
}
.\[counter-set\:_num_var\(--num-hibux\)\]{
  counter-set: num var(--num-hibux);
}
.\[height\:50vh\]{
  height: 50vh;
}
.\[mask-image\:linear-gradient\(to_bottom\2c transparent_0\%\2c black_0px\2c black_calc\(100\%-8px\)\2c transparent_100\%\)\]{
  -webkit-mask-image: linear-gradient(to bottom,transparent 0%,black 0px,black calc(100% - 8px),transparent 100%);
          mask-image: linear-gradient(to bottom,transparent 0%,black 0px,black calc(100% - 8px),transparent 100%);
}
.\[mask-image\:linear-gradient\(to_left\2c _transparent_0\%\2c _black_12px\2c _black_calc\(100\%_-_16px\)\2c _transparent_100\%\)\]{
  -webkit-mask-image: linear-gradient(to left, transparent 0%, black 12px, black calc(100% - 16px), transparent 100%);
          mask-image: linear-gradient(to left, transparent 0%, black 12px, black calc(100% - 16px), transparent 100%);
}
.\[mask-image\:linear-gradient\(to_left\2c transparent_0\%\2c black_10px\2c black_calc\(100\%-10px\)\2c transparent_100\%\)\]{
  -webkit-mask-image: linear-gradient(to left,transparent 0%,black 10px,black calc(100% - 10px),transparent 100%);
          mask-image: linear-gradient(to left,transparent 0%,black 10px,black calc(100% - 10px),transparent 100%);
}
.\[mask-image\:linear-gradient\(to_top\2c transparent_0\%\2c black_8px\2c black_calc\(100\%-8px\)\2c transparent_100\%\)\]{
  -webkit-mask-image: linear-gradient(to top,transparent 0%,black 8px,black calc(100% - 8px),transparent 100%);
          mask-image: linear-gradient(to top,transparent 0%,black 8px,black calc(100% - 8px),transparent 100%);
}
.\[max-height\:70vh\]{
  max-height: 70vh;
}
.\[object-position\:0\%40\%\]{
  -o-object-position: 0%40%;
     object-position: 0%40%;
}
.\[text-shadow\:0_2px_2px_rgba\(0\2c 0\2c 0\2c 0\.4\)\]{
  text-shadow: 0 2px 2px rgba(0,0,0,0.4);
}
.\[text-shadow\:1px_1px_3px_rgba\(0\2c 0\2c 0\2c 0\.25\)\]{
  text-shadow: 1px 1px 3px rgba(0,0,0,0.25);
}
.\[width\:calc\(100\%-20px\)\]{
  width: calc(100% - 20px);
}
@property --num-hibux {
  syntax: '<integer>';
  initial-value: 0;
  inherits: false;
}
.\*\:p-0 > *{
  padding: 0px;
}
@keyframes dialogFadeIn{
  0%{
    opacity: 0;
  }
  100%{
    opacity: 0.75;
  }
}
.backdrop\:animate-\[dialogFadeIn_0\.1s_ease-out\]::backdrop{
  animation: dialogFadeIn 0.1s ease-out;
}
.backdrop\:bg-dialog-backdrop::backdrop{
  background-color: #1B1C20B8;
}
.backdrop\:backdrop-blur-\[1px\]::backdrop{
  --tw-backdrop-blur: blur(1px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
.before\:absolute::before{
  content: var(--tw-content);
  position: absolute;
}
.before\:right-\[10\%\]::before{
  content: var(--tw-content);
  right: 10%;
}
.before\:size-\[15px\]::before{
  content: var(--tw-content);
  width: 15px;
  height: 15px;
}
.before\:rotate-45::before{
  content: var(--tw-content);
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.before\:border-l-\[1px\]::before{
  content: var(--tw-content);
  border-left-width: 1px;
}
.before\:border-t-\[1px\]::before{
  content: var(--tw-content);
  border-top-width: 1px;
}
.before\:border-\[none\]::before{
  content: var(--tw-content);
  border-color: none;
}
.before\:bg-greyscale-veryDark::before{
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(52 53 64 / var(--tw-bg-opacity));
}
.before\:content-\[\'\'\]::before{
  --tw-content: '';
  content: var(--tw-content);
}
.before\:content-\[\'Classic_avatar_equipped\'\]::before{
  --tw-content: 'Classic avatar equipped';
  content: var(--tw-content);
}
.before\:content-\[counter\(num\)\]::before{
  --tw-content: counter(num);
  content: var(--tw-content);
}
.before\:\[clip-path\:polygon\(0\%_0\%\2c _100\%_0\%\2c _0\%_100\%\)\]::before{
  content: var(--tw-content);
  clip-path: polygon(0% 0%, 100% 0%, 0% 100%);
}
.first\:border-t-\[1px\]:first-child{
  border-top-width: 1px;
}
.first\:border-greyscale-veryDark:first-child{
  --tw-border-opacity: 1;
  border-color: rgb(52 53 64 / var(--tw-border-opacity));
}
.last\:hidden:last-child{
  display: none;
}
.last\:border-b-\[1px\]:last-child{
  border-bottom-width: 1px;
}
.last\:border-greyscale-veryDark:last-child{
  --tw-border-opacity: 1;
  border-color: rgb(52 53 64 / var(--tw-border-opacity));
}
.odd\:bg-greyscale-veryDark:nth-child(odd){
  --tw-bg-opacity: 1;
  background-color: rgb(52 53 64 / var(--tw-bg-opacity));
}
.open\:pointer-events-auto[open]{
  pointer-events: auto;
}
.open\:scale-100[open]{
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.open\:opacity-100[open]{
  opacity: 1;
}
.checked\:ml-4:checked{
  margin-left: 1rem;
}
.checked\:mr-0:checked{
  margin-right: 0px;
}
.checked\:bg-accents-green-primary:checked{
  --tw-bg-opacity: 1;
  background-color: rgb(9 181 132 / var(--tw-bg-opacity));
}
.hover\:scale-105:hover{
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.hover\:scale-110:hover{
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.hover\:scale-\[1\.7\]:hover{
  --tw-scale-x: 1.7;
  --tw-scale-y: 1.7;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.hover\:cursor-pointer:hover{
  cursor: pointer;
}
.hover\:border:hover{
  border-width: 1px;
}
.hover\:border-solid:hover{
  border-style: solid;
}
.hover\:border-accents-green-primary:hover{
  --tw-border-opacity: 1;
  border-color: rgb(9 181 132 / var(--tw-border-opacity));
}
.hover\:border-greyscale-lightGrey:hover{
  --tw-border-opacity: 1;
  border-color: rgb(167 169 175 / var(--tw-border-opacity));
}
.hover\:bg-\[rgba\(255\2c 255\2c 255\2c 0\.1\)\]:hover{
  background-color: rgba(255,255,255,0.1);
}
.hover\:bg-greyscale-dark:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(69 70 84 / var(--tw-bg-opacity));
}
.hover\:bg-greyscale-hiberBlack:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(27 28 32 / var(--tw-bg-opacity));
}
.hover\:bg-greyscale-lightGrey:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(167 169 175 / var(--tw-bg-opacity));
}
.hover\:bg-greyscale-veryDark:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(52 53 64 / var(--tw-bg-opacity));
}
.hover\:bg-navigation-hoverBackground:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(52 53 64 / var(--tw-bg-opacity));
}
.hover\:bg-primary-light:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(234 53 64 / var(--tw-bg-opacity));
}
.hover\:bg-primary-main:hover{
  --tw-bg-opacity: 1;
  background-color: rgb(224 43 54 / var(--tw-bg-opacity));
}
.hover\:bg-opacity-50:hover{
  --tw-bg-opacity: 0.5;
}
.hover\:text-greyscale-dark:hover{
  --tw-text-opacity: 1;
  color: rgb(69 70 84 / var(--tw-text-opacity));
}
.hover\:text-greyscale-lightGrey:hover{
  --tw-text-opacity: 1;
  color: rgb(167 169 175 / var(--tw-text-opacity));
}
.hover\:text-greyscale-mediumGrey:hover{
  --tw-text-opacity: 1;
  color: rgb(144 147 152 / var(--tw-text-opacity));
}
.hover\:text-greyscale-veryLightGrey:hover{
  --tw-text-opacity: 1;
  color: rgb(233 233 236 / var(--tw-text-opacity));
}
.hover\:text-greyscale-white:hover{
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.hover\:text-link-hover-text:hover{
  --tw-text-opacity: 1;
  color: rgb(224 43 54 / var(--tw-text-opacity));
}
.hover\:text-primary-main:hover{
  --tw-text-opacity: 1;
  color: rgb(224 43 54 / var(--tw-text-opacity));
}
.hover\:underline:hover{
  text-decoration-line: underline;
}
.hover\:opacity-100:hover{
  opacity: 1;
}
.hover\:outline:hover{
  outline-style: solid;
}
.hover\:brightness-\[1\.2\]:hover{
  --tw-brightness: brightness(1.2);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.hover\:brightness-\[120\%\]:hover{
  --tw-brightness: brightness(120%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.hover\:saturate-150:hover{
  --tw-saturate: saturate(1.5);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.hover\:saturate-\[120\%\]:hover{
  --tw-saturate: saturate(120%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.hover\:saturate-\[150\%\]:hover{
  --tw-saturate: saturate(150%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.focus\:border-greyscale-mediumGrey:focus{
  --tw-border-opacity: 1;
  border-color: rgb(144 147 152 / var(--tw-border-opacity));
}
.active\:bg-greyscale-almostBlack:active{
  --tw-bg-opacity: 1;
  background-color: rgb(41 42 49 / var(--tw-bg-opacity));
}
.active\:bg-greyscale-hiberBlack:active{
  --tw-bg-opacity: 1;
  background-color: rgb(27 28 32 / var(--tw-bg-opacity));
}
.active\:bg-primary-dark:active{
  --tw-bg-opacity: 1;
  background-color: rgb(214 33 44 / var(--tw-bg-opacity));
}
.active\:outline-greyscale-white:active{
  outline-color: #FFFFFF;
}
.disabled\:cursor-default:disabled{
  cursor: default;
}
.disabled\:cursor-not-allowed:disabled{
  cursor: not-allowed;
}
.disabled\:cursor-pointer:disabled{
  cursor: pointer;
}
.disabled\:\!bg-greyscale-dark:disabled{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(69 70 84 / var(--tw-bg-opacity)) !important;
}
.disabled\:bg-greyscale-almostBlack:disabled{
  --tw-bg-opacity: 1;
  background-color: rgb(41 42 49 / var(--tw-bg-opacity));
}
.disabled\:bg-greyscale-veryDark:disabled{
  --tw-bg-opacity: 1;
  background-color: rgb(52 53 64 / var(--tw-bg-opacity));
}
.disabled\:text-greyscale-lightGrey:disabled{
  --tw-text-opacity: 1;
  color: rgb(167 169 175 / var(--tw-text-opacity));
}
.disabled\:text-greyscale-white:disabled{
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.group:hover .group-hover\:pointer-events-auto{
  pointer-events: auto;
}
.group:hover .group-hover\:visible{
  visibility: visible;
}
.group:hover .group-hover\:block{
  display: block;
}
.group:hover .group-hover\:translate-x-0{
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.group:hover .group-hover\:touch-auto{
  touch-action: auto;
}
.group:hover .group-hover\:bg-primary-main{
  --tw-bg-opacity: 1;
  background-color: rgb(224 43 54 / var(--tw-bg-opacity));
}
.group:hover .group-hover\:pr-12{
  padding-right: 3rem;
}
.group:hover .group-hover\:opacity-100{
  opacity: 1;
}
.peer:checked ~ .peer-checked\:border{
  border-width: 1px;
}
.peer:checked ~ .peer-checked\:border-solid{
  border-style: solid;
}
.peer:checked ~ .peer-checked\:border-greyscale-lightGrey{
  --tw-border-opacity: 1;
  border-color: rgb(167 169 175 / var(--tw-border-opacity));
}
.peer:checked ~ .peer-checked\:from-greyscale-dark{
  --tw-gradient-from: #454654 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(69 70 84 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.peer:checked ~ .peer-checked\:via-greyscale-dark{
  --tw-gradient-to: rgb(69 70 84 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #454654 var(--tw-gradient-via-position), var(--tw-gradient-to);
}
.peer:checked ~ .peer-checked\:to-greyscale-almostBlack{
  --tw-gradient-to: #292A31 var(--tw-gradient-to-position);
}
.peer:checked ~ .peer-checked\:shadow-emoteEquip{
  --tw-shadow: 0 0 7px 1px #A7A9AF, 0 0 7px 1px #A7A9AF;
  --tw-shadow-colored: 0 0 7px 1px var(--tw-shadow-color), 0 0 7px 1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.peer:focus ~ .peer-focus\:text-greyscale-veryLightGrey{
  --tw-text-opacity: 1;
  color: rgb(233 233 236 / var(--tw-text-opacity));
}
.peer:disabled ~ .peer-disabled\:brightness-90{
  --tw-brightness: brightness(.9);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.peer:-moz-placeholder-shown:not(:focus) ~ .peer-placeholder-shown\:peer-\[\&\:not\(\:focus\)\]\:pointer-events-none{
  pointer-events: none;
}
.peer:placeholder-shown:not(:focus) ~ .peer-placeholder-shown\:peer-\[\&\:not\(\:focus\)\]\:pointer-events-none{
  pointer-events: none;
}
.peer:-moz-placeholder-shown:not(:focus) ~ .peer-placeholder-shown\:peer-\[\&\:not\(\:focus\)\]\:h-0{
  height: 0px;
}
.peer:placeholder-shown:not(:focus) ~ .peer-placeholder-shown\:peer-\[\&\:not\(\:focus\)\]\:h-0{
  height: 0px;
}
.peer:-moz-placeholder-shown:not(:focus) ~ .peer-placeholder-shown\:peer-\[\&\:not\(\:focus\)\]\:opacity-0{
  opacity: 0;
}
.peer:placeholder-shown:not(:focus) ~ .peer-placeholder-shown\:peer-\[\&\:not\(\:focus\)\]\:opacity-0{
  opacity: 0;
}
.data-\[visible\=false\]\:pointer-events-none[data-visible=false]{
  pointer-events: none;
}
.data-\[loading\=true\]\:invisible[data-loading=true]{
  visibility: hidden;
}
.data-\[above\=false\]\:top-\[calc\(100\%\+8px\)\][data-above=false]{
  top: calc(100% + 8px);
}
.data-\[above\=true\]\:bottom-\[34px\][data-above=true]{
  bottom: 34px;
}
.data-\[hide-navigation\=true\]\:top-0[data-hide-navigation=true]{
  top: 0px;
}
.data-\[hide-navigation\=true\]\:top-4[data-hide-navigation=true]{
  top: 1rem;
}
.data-\[signUpPage\=true\]\:top-20[data-signUpPage=true]{
  top: 5rem;
}
.data-\[collapsed\=true\]\:mb-4[data-collapsed=true]{
  margin-bottom: 1rem;
}
.data-\[visible\=true\]\:ml-\[-24px\][data-visible=true]{
  margin-left: -24px;
}
.data-\[show-icon\=true\]\:block[data-show-icon=true]{
  display: block;
}
.data-\[large\=false\]\:flex[data-large=false]{
  display: flex;
}
.data-\[only-collapsed\=true\]\:hidden[data-only-collapsed=true]{
  display: none;
}
.data-\[hide-navigation\=true\]\:h-\[100dvh\][data-hide-navigation=true]{
  height: 100dvh;
}
.data-\[hide-navigation\=true\]\:h-screen[data-hide-navigation=true]{
  height: 100vh;
}
.data-\[large\=true\]\:h-\[400px\][data-large=true]{
  height: 400px;
}
.data-\[open\=true\]\:h-\[300px\][data-open=true]{
  height: 300px;
}
.data-\[open\=true\]\:h-\[35px\][data-open=true]{
  height: 35px;
}
.data-\[open\=true\]\:max-h-\[calc\(100\%-56px\)\][data-open=true]{
  max-height: calc(100% - 56px);
}
.data-\[large\=false\]\:w-full[data-large=false]{
  width: 100%;
}
.data-\[visible\=true\]\:w-12[data-visible=true]{
  width: 3rem;
}
.data-\[large\=true\]\:max-w-\[450px\][data-large=true]{
  max-width: 450px;
}
.data-\[long-name\=true\]\:max-w-\[200px\][data-long-name=true]{
  max-width: 200px;
}
.data-\[focused\=true\]\:translate-y-\[-26px\][data-focused=true]{
  --tw-translate-y: -26px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[focused\=true\]\:translate-y-\[-30px\][data-focused=true]{
  --tw-translate-y: -30px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[onload\=true\]\:translate-y-0[data-onload=true]{
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[open\=true\]\:translate-x-\[-100\%\][data-open=true]{
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[open\=true\]\:translate-y-0[data-open=true]{
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[ready\=true\]\:translate-y-0[data-ready=true]{
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[visible\=true\]\:translate-x-0[data-visible=true]{
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[direction\=left\]\:-rotate-90[data-direction=left]{
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[direction\=right\]\:rotate-90[data-direction=right]{
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[visible\=true\]\:rotate-90[data-visible=true]{
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[animation\=true\]\:scale-110[data-animation=true]{
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[focused\=true\]\:scale-100[data-focused=true]{
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[focused\=true\]\:scale-\[1\.3\][data-focused=true]{
  --tw-scale-x: 1.3;
  --tw-scale-y: 1.3;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[onload\=true\]\:scale-100[data-onload=true]{
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[pointer\=true\]\:cursor-pointer[data-pointer=true]{
  cursor: pointer;
}
.data-\[full-width\=true\]\:grid-cols-1[data-full-width=true]{
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.data-\[is-open\=true\]\:grid-rows-\[1fr\][data-is-open=true]{
  grid-template-rows: 1fr;
}
.data-\[collapsed\=true\]\:flex-row[data-collapsed=true]{
  flex-direction: row;
}
.data-\[small\=true\]\:flex-row[data-small=true]{
  flex-direction: row;
}
.data-\[large\=false\]\:flex-col[data-large=false]{
  flex-direction: column;
}
.data-\[large\=false\]\:items-start[data-large=false]{
  align-items: flex-start;
}
.data-\[small\=true\]\:justify-between[data-small=true]{
  justify-content: space-between;
}
.data-\[open\=true\]\:overflow-auto[data-open=true]{
  overflow: auto;
}
.data-\[no-border\=true\]\:border-0[data-no-border=true]{
  border-width: 0px;
}
.data-\[red\=true\]\:border-primary-main[data-red=true]{
  --tw-border-opacity: 1;
  border-color: rgb(224 43 54 / var(--tw-border-opacity));
}
.data-\[selected\=true\]\:border-greyscale-veryLightGrey[data-selected=true]{
  --tw-border-opacity: 1;
  border-color: rgb(233 233 236 / var(--tw-border-opacity));
}
.data-\[color\=dark\]\:bg-greyscale-almostBlack[data-color=dark]{
  --tw-bg-opacity: 1;
  background-color: rgb(41 42 49 / var(--tw-bg-opacity));
}
.data-\[color\=red\]\:bg-primary-main[data-color=red]{
  --tw-bg-opacity: 1;
  background-color: rgb(224 43 54 / var(--tw-bg-opacity));
}
.data-\[is-mine\=true\]\:bg-greyscale-veryDark[data-is-mine=true]{
  --tw-bg-opacity: 1;
  background-color: rgb(52 53 64 / var(--tw-bg-opacity));
}
.data-\[light-background\=true\]\:bg-greyscale-white[data-light-background=true]{
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.data-\[only-selected\=true\]\:bg-greyscale-dark[data-only-selected=true]{
  --tw-bg-opacity: 1;
  background-color: rgb(69 70 84 / var(--tw-bg-opacity));
}
.data-\[red\=true\]\:bg-primary-main[data-red=true]{
  --tw-bg-opacity: 1;
  background-color: rgb(224 43 54 / var(--tw-bg-opacity));
}
.data-\[selected\=true\]\:bg-greyscale-lightGrey[data-selected=true]{
  --tw-bg-opacity: 1;
  background-color: rgb(167 169 175 / var(--tw-bg-opacity));
}
.data-\[variant\=level\]\:bg-greyscale-veryDark[data-variant=level]{
  --tw-bg-opacity: 1;
  background-color: rgb(52 53 64 / var(--tw-bg-opacity));
}
.data-\[light-background\=true\]\:bg-opacity-30[data-light-background=true]{
  --tw-bg-opacity: 0.3;
}
.data-\[variant\=daily\]\:bg-gradient-to-t[data-variant=daily]{
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
}
.data-\[small\=true\]\:px-4[data-small=true]{
  padding-left: 1rem;
  padding-right: 1rem;
}
.data-\[small\=true\]\:py-2[data-small=true]{
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.data-\[visible\=true\]\:pr-0[data-visible=true]{
  padding-right: 0px;
}
.data-\[bold\=true\]\:font-bold[data-bold=true]{
  font-weight: 700;
}
.data-\[both\=true\]\:text-text-info[data-both=true]{
  --tw-text-opacity: 1;
  color: rgb(167 169 175 / var(--tw-text-opacity));
}
.data-\[highlighted\=true\]\:text-greyscale-white[data-highlighted=true]{
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.data-\[open\=true\]\:text-greyscale-white[data-open=true]{
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.data-\[red\=true\]\:text-primary-main[data-red=true]{
  --tw-text-opacity: 1;
  color: rgb(224 43 54 / var(--tw-text-opacity));
}
.data-\[animation\=true\]\:opacity-100[data-animation=true]{
  opacity: 1;
}
.data-\[focused\=false\]\:opacity-0[data-focused=false]{
  opacity: 0;
}
.data-\[focused\=true\]\:opacity-0[data-focused=true]{
  opacity: 0;
}
.data-\[focused\=true\]\:opacity-100[data-focused=true]{
  opacity: 1;
}
.data-\[onload\=true\]\:opacity-100[data-onload=true]{
  opacity: 1;
}
.data-\[ready\=true\]\:opacity-100[data-ready=true]{
  opacity: 1;
}
.data-\[remove-animation\=true\]\:opacity-0[data-remove-animation=true]{
  opacity: 0;
}
.data-\[render\=true\]\:opacity-100[data-render=true]{
  opacity: 1;
}
.data-\[visible\=true\]\:opacity-0[data-visible=true]{
  opacity: 0;
}
.data-\[visible\=true\]\:opacity-100[data-visible=true]{
  opacity: 1;
}
.data-\[glow\=true\]\:shadow-shopCardExclusiveSmall[data-glow=true]{
  --tw-shadow: 0 0 14px 0 rgba(233, 168, 0, 0.75), 0 0 7px 0 rgba(233, 168, 0, 0.75);
  --tw-shadow-colored: 0 0 14px 0 var(--tw-shadow-color), 0 0 7px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.data-\[animation\=true\]\:blur-sm[data-animation=true]{
  --tw-blur: blur(4px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.data-\[variant\=daily\]\:data-\[animation\=true\]\:drop-shadow-\[0_0_10px_\#05A074\][data-animation=true][data-variant=daily]{
  --tw-drop-shadow: drop-shadow(0 0 10px #05A074);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.data-\[variant\=level\]\:data-\[animation\=true\]\:drop-shadow-\[0_0_10px_\#A32A97\][data-animation=true][data-variant=level]{
  --tw-drop-shadow: drop-shadow(0 0 10px #A32A97);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.data-\[greyscale\=true\]\:grayscale[data-greyscale=true]{
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.data-\[has-glow\=true\]\:\[filter\:_drop-shadow\(0px_0_12px_rgba\(240\2c 220\2c 122\2c 0\.3\)\)_drop-shadow\(-2px_0_12px_rgba\(240\2c 220\2c 122\2c 0\.3\)\)_drop-shadow\(0_0_12px_rgba\(240\2c 220\2c 122\2c 0\.3\)\)\][data-has-glow=true]{
  filter: drop-shadow(0px 0 12px rgba(240,220,122,0.3)) drop-shadow(-2px 0 12px rgba(240,220,122,0.3)) drop-shadow(0 0 12px rgba(240,220,122,0.3));
}
.data-\[has-glow\=true\]\:\[filter\:_drop-shadow\(2px_0_3px_rgba\(255\2c 255\2c 255\2c 0\.4\)\)_drop-shadow\(-2px_0_3px_rgba\(255\2c 255\2c 255\2c 0\.4\)\)_drop-shadow\(0px_2px_3px_rgba\(255\2c 255\2c 255\2c 0\.4\)\)_drop-shadow\(0_-2px_3px_rgba\(255\2c 255\2c 255\2c 0\.4\)\)\][data-has-glow=true]{
  filter: drop-shadow(2px 0 3px rgba(255,255,255,0.4)) drop-shadow(-2px 0 3px rgba(255,255,255,0.4)) drop-shadow(0px 2px 3px rgba(255,255,255,0.4)) drop-shadow(0 -2px 3px rgba(255,255,255,0.4));
}
.data-\[has-glow\=true\]\:\[filter\:_drop-shadow\(2px_0_4px_rgba\(255\2c 255\2c 255\2c 0\.4\)\)_drop-shadow\(-2px_0_4px_rgba\(255\2c 255\2c 255\2c 0\.4\)\)_drop-shadow\(0px_0_4px_rgba\(255\2c 255\2c 255\2c 0\.4\)\)\][data-has-glow=true]{
  filter: drop-shadow(2px 0 4px rgba(255,255,255,0.4)) drop-shadow(-2px 0 4px rgba(255,255,255,0.4)) drop-shadow(0px 0 4px rgba(255,255,255,0.4));
}
.data-\[animation\=true\]\:\[stroke-dasharray\:_360_360\][data-animation=true]{
  stroke-dasharray: 360 360;
}
.data-\[has-glow\=true\]\:\[text-shadow\:2px_0_3px_rgba\(255\2c 255\2c 255\2c 0\.4\)\2c 0_-2px_3px_rgba\(255\2c 255\2c 255\2c 0\.4\)\2c -2px_0_4px_rgba\(255\2c 255\2c 255\2c 0\.4\)\2c 0px_2px_4px_rgba\(255\2c 255\2c 255\2c 0\.4\)\][data-has-glow=true]{
  text-shadow: 2px 0 3px rgba(255,255,255,0.4),0 -2px 3px rgba(255,255,255,0.4),-2px 0 4px rgba(255,255,255,0.4),0px 2px 4px rgba(255,255,255,0.4);
}
.data-\[above\=false\]\:before\:-top-2[data-above=false]::before{
  content: var(--tw-content);
  top: -0.5rem;
}
.data-\[above\=true\]\:before\:-bottom-2[data-above=true]::before{
  content: var(--tw-content);
  bottom: -0.5rem;
}
.data-\[above\=true\]\:before\:rotate-\[225deg\][data-above=true]::before{
  content: var(--tw-content);
  --tw-rotate: 225deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[hover\=true\]\:hover\:cursor-default:hover[data-hover=true]{
  cursor: default;
}
.data-\[light-background\=true\]\:hover\:bg-greyscale-white:hover[data-light-background=true]{
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.data-\[light-background\=true\]\:hover\:bg-opacity-50:hover[data-light-background=true]{
  --tw-bg-opacity: 0.5;
}
@media (pointer: coarse){
  .pointer-coarse\:block{
    display: block;
  }
  .pointer-coarse\:hidden{
    display: none;
  }
  .pointer-coarse\:hover\:border-transparent:hover{
    border-color: transparent;
  }
}
@media (hover: hover){
  .group:hover .has-hover\:group-hover\:translate-y-0{
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .group:hover .has-hover\:group-hover\:opacity-100{
    opacity: 1;
  }
}
@supports (overflow:clip){
  .supports-\[overflow\:clip\]\:overflow-x-clip{
    overflow-x: clip;
  }
}
@media not all and (min-width: 768px){
  .max-medium\:mb-0{
    margin-bottom: 0px;
  }
  .max-medium\:ml-0{
    margin-left: 0px;
  }
  .max-medium\:mt-1{
    margin-top: 0.25rem;
  }
  .max-medium\:flex{
    display: flex;
  }
  .max-medium\:flex-col{
    flex-direction: column;
  }
}
@media (min-width: 320px){
  .extraSmall\:p-\[1\.7rem\]{
    padding: 1.7rem;
  }
}
@media (min-width: 430px){
  .small\:-right-\[1px\]{
    right: -1px;
  }
  .small\:mx-0{
    margin-left: 0px;
    margin-right: 0px;
  }
  .small\:mb-0{
    margin-bottom: 0px;
  }
  .small\:mt-24{
    margin-top: 6rem;
  }
  .small\:mt-\[30\%\]{
    margin-top: 30%;
  }
  .small\:flex{
    display: flex;
  }
  .small\:h-4{
    height: 1rem;
  }
  .small\:w-48{
    width: 12rem;
  }
  .small\:w-full{
    width: 100%;
  }
  .small\:min-w-\[230px\]{
    min-width: 230px;
  }
  .small\:min-w-\[320px\]{
    min-width: 320px;
  }
  .small\:max-w-\[450px\]{
    max-width: 450px;
  }
  .small\:max-w-\[80\%\]{
    max-width: 80%;
  }
  .small\:grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .small\:gap-4{
    gap: 1rem;
  }
  .small\:rounded-lg{
    border-radius: 0.5rem;
  }
  .small\:p-4{
    padding: 1rem;
  }
  .small\:p-6{
    padding: 1.5rem;
  }
  .small\:px-0{
    padding-left: 0px;
    padding-right: 0px;
  }
  .small\:px-10{
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .small\:px-16{
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .small\:px-6{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .small\:px-7{
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
  .small\:py-11{
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }
  .small\:pb-0{
    padding-bottom: 0px;
  }
  .small\:pb-14{
    padding-bottom: 3.5rem;
  }
  .small\:pr-8{
    padding-right: 2rem;
  }
  .small\:text-h1{
    font-size: clamp(2.75rem, 1.9779rem + 2.5735vw, 3.625rem);
    line-height: 1.1;
  }
  .small\:text-h2{
    font-size: clamp(2rem, 1.3382rem + 2.2059vw, 2.75rem);
    line-height: 1.09;
  }
  .before\:small\:right-\[3\.5\%\]::before{
    content: var(--tw-content);
    right: 3.5%;
  }
}
@media (min-width: 568px){
  .mediumSmall\:relative{
    position: relative;
  }
  .mediumSmall\:-bottom-16{
    bottom: -4rem;
  }
  .mediumSmall\:bottom-3{
    bottom: 0.75rem;
  }
  .mediumSmall\:right-\[19px\]{
    right: 19px;
  }
  .mediumSmall\:top-\[calc\(56px\+1rem\)\]{
    top: calc(56px + 1rem);
  }
  .mediumSmall\:mt-10{
    margin-top: 2.5rem;
  }
  .mediumSmall\:mt-8{
    margin-top: 2rem;
  }
  .mediumSmall\:mt-\[-50px\]{
    margin-top: -50px;
  }
  .mediumSmall\:block{
    display: block;
  }
  .mediumSmall\:flex{
    display: flex;
  }
  .mediumSmall\:hidden{
    display: none;
  }
  .mediumSmall\:aspect-auto{
    aspect-ratio: auto;
  }
  .mediumSmall\:h-\[400px\]{
    height: 400px;
  }
  .mediumSmall\:max-h-\[85vh\]{
    max-height: 85vh;
  }
  .mediumSmall\:w-2\/5{
    width: 40%;
  }
  .mediumSmall\:w-full{
    width: 100%;
  }
  .mediumSmall\:max-w-\[320px\]{
    max-width: 320px;
  }
  .mediumSmall\:basis-\[calc\(100\%\/3\)\]{
    flex-basis: calc(100% / 3);
  }
  .mediumSmall\:grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .mediumSmall\:flex-row{
    flex-direction: row;
  }
  .mediumSmall\:flex-col{
    flex-direction: column;
  }
  .mediumSmall\:items-start{
    align-items: flex-start;
  }
  .mediumSmall\:overflow-hidden{
    overflow: hidden;
  }
  .mediumSmall\:rounded-lg{
    border-radius: 0.5rem;
  }
  .mediumSmall\:bg-greyscale-almostBlack{
    --tw-bg-opacity: 1;
    background-color: rgb(41 42 49 / var(--tw-bg-opacity));
  }
  .mediumSmall\:bg-gradient-to-r{
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
  }
  .mediumSmall\:from-greyscale-almostBlack{
    --tw-gradient-from: #292A31 var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(41 42 49 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }
  .mediumSmall\:from-60\%{
    --tw-gradient-from-position: 60%;
  }
  .mediumSmall\:via-greyscale-almostBlack\/75{
    --tw-gradient-to: rgb(41 42 49 / 0)  var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), rgb(41 42 49 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to);
  }
  .mediumSmall\:via-15\%{
    --tw-gradient-via-position: 15%;
  }
  .mediumSmall\:via-\[76\.5\%\]{
    --tw-gradient-via-position: 76.5%;
  }
  .mediumSmall\:to-greyscale-almostBlack\/5{
    --tw-gradient-to: rgb(41 42 49 / 0.05) var(--tw-gradient-to-position);
  }
  .mediumSmall\:to-30\%{
    --tw-gradient-to-position: 30%;
  }
  .mediumSmall\:object-\[40\%_0\%\]{
    -o-object-position: 40% 0%;
       object-position: 40% 0%;
  }
  .mediumSmall\:p-2{
    padding: 0.5rem;
  }
  .mediumSmall\:px-0{
    padding-left: 0px;
    padding-right: 0px;
  }
  .mediumSmall\:px-16{
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .mediumSmall\:px-24{
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .mediumSmall\:data-\[open\=true\]\:translate-x-\[calc\(-100\%-32px\)\][data-open=true]{
    --tw-translate-x: calc(-100% - 32px);
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .data-\[large\=false\]\:mediumSmall\:flex-row[data-large=false]{
    flex-direction: row;
  }
  .data-\[large\=false\]\:mediumSmall\:items-end[data-large=false]{
    align-items: flex-end;
  }
  .data-\[large\=false\]\:mediumSmall\:justify-between[data-large=false]{
    justify-content: space-between;
  }
}
@media (min-width: 700px){
  .tabletPortrait\:row-start-2{
    grid-row-start: 2;
  }
  .tabletPortrait\:grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .tabletPortrait\:flex-row{
    flex-direction: row;
  }
  .tabletPortrait\:flex-nowrap{
    flex-wrap: nowrap;
  }
  .tabletPortrait\:justify-around{
    justify-content: space-around;
  }
}
@media (min-width: 768px){
  .medium\:static{
    position: static;
  }
  .medium\:relative{
    position: relative;
  }
  .medium\:-bottom-3{
    bottom: -0.75rem;
  }
  .medium\:-right-64{
    right: -16rem;
  }
  .medium\:bottom-0{
    bottom: 0px;
  }
  .medium\:bottom-5{
    bottom: 1.25rem;
  }
  .medium\:left-10{
    left: 2.5rem;
  }
  .medium\:top-0{
    top: 0px;
  }
  .medium\:top-2{
    top: 0.5rem;
  }
  .medium\:top-\[76px\]{
    top: 76px;
  }
  .medium\:row-start-1{
    grid-row-start: 1;
  }
  .medium\:row-end-2{
    grid-row-end: 2;
  }
  .medium\:mx-0{
    margin-left: 0px;
    margin-right: 0px;
  }
  .medium\:mx-4{
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .medium\:mb-0{
    margin-bottom: 0px;
  }
  .medium\:mb-16{
    margin-bottom: 4rem;
  }
  .medium\:mb-2{
    margin-bottom: 0.5rem;
  }
  .medium\:mb-4{
    margin-bottom: 1rem;
  }
  .medium\:mr-2{
    margin-right: 0.5rem;
  }
  .medium\:mt-0{
    margin-top: 0px;
  }
  .medium\:mt-2{
    margin-top: 0.5rem;
  }
  .medium\:mt-4{
    margin-top: 1rem;
  }
  .medium\:mt-6{
    margin-top: 1.5rem;
  }
  .medium\:block{
    display: block;
  }
  .medium\:flex{
    display: flex;
  }
  .medium\:hidden{
    display: none;
  }
  .medium\:size-\[100px\]{
    width: 100px;
    height: 100px;
  }
  .medium\:h-10{
    height: 2.5rem;
  }
  .medium\:h-12{
    height: 3rem;
  }
  .medium\:h-56{
    height: 14rem;
  }
  .medium\:h-\[430px\]{
    height: 430px;
  }
  .medium\:h-full{
    height: 100%;
  }
  .medium\:max-h-\[350px\]{
    max-height: 350px;
  }
  .medium\:max-h-\[500px\]{
    max-height: 500px;
  }
  .medium\:max-h-\[712px\]{
    max-height: 712px;
  }
  .medium\:max-h-\[calc\(700px-56px\)\]{
    max-height: calc(700px - 56px);
  }
  .medium\:min-h-8{
    min-height: 2rem;
  }
  .medium\:min-h-\[260px\]{
    min-height: 260px;
  }
  .medium\:w-10{
    width: 2.5rem;
  }
  .medium\:w-\[auto\]{
    width: auto;
  }
  .medium\:w-full{
    width: 100%;
  }
  .medium\:min-w-\[300px\]{
    min-width: 300px;
  }
  .medium\:min-w-\[400px\]{
    min-width: 400px;
  }
  .medium\:max-w-\[323px\]{
    max-width: 323px;
  }
  .medium\:max-w-\[400px\]{
    max-width: 400px;
  }
  .medium\:max-w-\[856px\]{
    max-width: 856px;
  }
  .medium\:max-w-\[912px\]{
    max-width: 912px;
  }
  .medium\:basis-\[calc\(100\%\/5\)\]{
    flex-basis: calc(100% / 5);
  }
  .medium\:grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .medium\:grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .medium\:grid-cols-4{
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .medium\:grid-cols-\[1fr\]{
    grid-template-columns: 1fr;
  }
  .medium\:grid-cols-\[1fr_1fr_1fr\]{
    grid-template-columns: 1fr 1fr 1fr;
  }
  .medium\:grid-cols-\[1fr_auto_minmax\(max-content\2c _1fr\)\]{
    grid-template-columns: 1fr auto minmax(max-content, 1fr);
  }
  .medium\:grid-cols-\[2fr\2c 1fr\]{
    grid-template-columns: 2fr 1fr;
  }
  .medium\:flex-row{
    flex-direction: row;
  }
  .medium\:flex-col{
    flex-direction: column;
  }
  .medium\:items-center{
    align-items: center;
  }
  .medium\:justify-center{
    justify-content: center;
  }
  .medium\:gap-6{
    gap: 1.5rem;
  }
  .medium\:gap-8{
    gap: 2rem;
  }
  .medium\:gap-y-8{
    row-gap: 2rem;
  }
  .medium\:overflow-hidden{
    overflow: hidden;
  }
  .medium\:border-solid{
    border-style: solid;
  }
  .medium\:bg-opacity-0{
    --tw-bg-opacity: 0;
  }
  .medium\:object-cover{
    -o-object-fit: cover;
       object-fit: cover;
  }
  .medium\:p-0{
    padding: 0px;
  }
  .medium\:p-6{
    padding: 1.5rem;
  }
  .medium\:p-8{
    padding: 2rem;
  }
  .medium\:px-0{
    padding-left: 0px;
    padding-right: 0px;
  }
  .medium\:px-10{
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .medium\:px-16{
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .medium\:px-2{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .medium\:px-4{
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .medium\:px-8{
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .medium\:py-16{
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .medium\:pb-16{
    padding-bottom: 4rem;
  }
  .medium\:pb-20{
    padding-bottom: 5rem;
  }
  .medium\:pb-\[7\.5rem\]{
    padding-bottom: 7.5rem;
  }
  .medium\:pl-\[88px\]{
    padding-left: 88px;
  }
  .medium\:pr-24{
    padding-right: 6rem;
  }
  .medium\:pr-6{
    padding-right: 1.5rem;
  }
  .medium\:pt-0{
    padding-top: 0px;
  }
  .medium\:pt-14{
    padding-top: 3.5rem;
  }
  .medium\:pt-28{
    padding-top: 7rem;
  }
  .medium\:transition-colors{
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  .group:hover .medium\:group-hover\:pr-14{
    padding-right: 3.5rem;
  }
  .data-\[full-width\=true\]\:medium\:mt-4[data-full-width=true]{
    margin-top: 1rem;
  }
  .data-\[full-width\=true\]\:medium\:mt-8[data-full-width=true]{
    margin-top: 2rem;
  }
}
@media (min-width: 830px){
  .breakMobile\:bottom-0{
    bottom: 0px;
  }
  .breakMobile\:right-0{
    right: 0px;
  }
  .breakMobile\:mb-16{
    margin-bottom: 4rem;
  }
  .breakMobile\:ml-16{
    margin-left: 4rem;
  }
  .breakMobile\:block{
    display: block;
  }
  .breakMobile\:flex{
    display: flex;
  }
  .breakMobile\:hidden{
    display: none;
  }
  .breakMobile\:min-w-\[320px\]{
    min-width: 320px;
  }
  .breakMobile\:grid-cols-\[1fr_1fr\]{
    grid-template-columns: 1fr 1fr;
  }
  .breakMobile\:grid-cols-\[repeat\(3\2c _calc\(33\.33\%_-_\(0\.625rem_-_0\.625rem_\/_3\)\)\)\]{
    grid-template-columns: repeat(3, calc(33.33% - (0.625rem - 0.625rem / 3)));
  }
  .breakMobile\:pb-2{
    padding-bottom: 0.5rem;
  }
}
@media (min-width: 900px){
  .tabletLandscape\:sticky{
    position: sticky;
  }
  .tabletLandscape\:order-1{
    order: 1;
  }
  .tabletLandscape\:w-\[100\%\]{
    width: 100%;
  }
  .tabletLandscape\:w-full{
    width: 100%;
  }
  .tabletLandscape\:max-w-\[437px\]{
    max-width: 437px;
  }
  .tabletLandscape\:grid-cols-4{
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .tabletLandscape\:grid-cols-\[2fr_1\.25fr\]{
    grid-template-columns: 2fr 1.25fr;
  }
  .tabletLandscape\:gap-16{
    gap: 4rem;
  }
}
@media (min-width: 1024px){
  .mediumLarge\:absolute{
    position: absolute;
  }
  .mediumLarge\:sticky{
    position: sticky;
  }
  .mediumLarge\:-bottom-20{
    bottom: -5rem;
  }
  .mediumLarge\:-bottom-44{
    bottom: -11rem;
  }
  .mediumLarge\:left-4{
    left: 1rem;
  }
  .mediumLarge\:right-6{
    right: 1.5rem;
  }
  .mediumLarge\:right-8{
    right: 2rem;
  }
  .mediumLarge\:right-\[88px\]{
    right: 88px;
  }
  .mediumLarge\:top-4{
    top: 1rem;
  }
  .mediumLarge\:top-6{
    top: 1.5rem;
  }
  .mediumLarge\:top-8{
    top: 2rem;
  }
  .mediumLarge\:top-\[72px\]{
    top: 72px;
  }
  .mediumLarge\:col-span-2{
    grid-column: span 2 / span 2;
  }
  .mediumLarge\:col-span-3{
    grid-column: span 3 / span 3;
  }
  .mediumLarge\:mb-20{
    margin-bottom: 5rem;
  }
  .mediumLarge\:ml-auto{
    margin-left: auto;
  }
  .mediumLarge\:mt-0{
    margin-top: 0px;
  }
  .mediumLarge\:block{
    display: block;
  }
  .mediumLarge\:hidden{
    display: none;
  }
  .mediumLarge\:h-\[122px\]{
    height: 122px;
  }
  .mediumLarge\:h-\[49px\]{
    height: 49px;
  }
  .mediumLarge\:h-\[700px\]{
    height: 700px;
  }
  .mediumLarge\:max-h-body{
    max-height: calc(100vh - 56px);
  }
  .mediumLarge\:max-h-none{
    max-height: none;
  }
  .mediumLarge\:w-\[280px\]{
    width: 280px;
  }
  .mediumLarge\:w-\[600px\]{
    width: 600px;
  }
  .mediumLarge\:w-\[883px\]{
    width: 883px;
  }
  .mediumLarge\:w-\[calc\(50\%-0\.75rem\)\]{
    width: calc(50% - 0.75rem);
  }
  .mediumLarge\:w-auto{
    width: auto;
  }
  .mediumLarge\:min-w-\[244px\]{
    min-width: 244px;
  }
  .mediumLarge\:min-w-\[calc\(900px-4rem\)\]{
    min-width: calc(900px - 4rem);
  }
  .mediumLarge\:max-w-\[400px\]{
    max-width: 400px;
  }
  .mediumLarge\:max-w-\[800px\]{
    max-width: 800px;
  }
  .mediumLarge\:basis-\[calc\(100\%\/7\)\]{
    flex-basis: calc(100% / 7);
  }
  .mediumLarge\:grid-cols-3{
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .mediumLarge\:grid-cols-5{
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .mediumLarge\:grid-cols-\[1fr_1fr\]{
    grid-template-columns: 1fr 1fr;
  }
  .mediumLarge\:grid-cols-\[repeat\(2\2c _calc\(50\%-1rem\/2\)\)\]{
    grid-template-columns: repeat(2, calc(50% - 1rem / 2));
  }
  .mediumLarge\:grid-cols-\[repeat\(4\2c _calc\(25\%_-_0\.75rem\)\)\]{
    grid-template-columns: repeat(4, calc(25% - 0.75rem));
  }
  .mediumLarge\:grid-cols-none{
    grid-template-columns: none;
  }
  .mediumLarge\:flex-row{
    flex-direction: row;
  }
  .mediumLarge\:gap-1{
    gap: 0.25rem;
  }
  .mediumLarge\:gap-2{
    gap: 0.5rem;
  }
  .mediumLarge\:gap-4{
    gap: 1rem;
  }
  .mediumLarge\:gap-8{
    gap: 2rem;
  }
  .mediumLarge\:gap-x-6{
    -moz-column-gap: 1.5rem;
         column-gap: 1.5rem;
  }
  .mediumLarge\:gap-y-8{
    row-gap: 2rem;
  }
  .mediumLarge\:self-end{
    align-self: flex-end;
  }
  .mediumLarge\:rounded-lg{
    border-radius: 0.5rem;
  }
  .mediumLarge\:bg-\[radial-gradient\(36\.09\%_50\%_at_50\%_50\%\2c _\#FFFFFF80_0\%\2c rgba\(39\2c 39\2c 39\2c 0\.2\)_70\%\2c _rgba\(39\2c 39\2c 39\2c 0\)_100\%\)\]{
    background-image: radial-gradient(36.09% 50% at 50% 50%, #FFFFFF80 0%,rgba(39,39,39,0.2) 70%, rgba(39,39,39,0) 100%);
  }
  .mediumLarge\:px-4{
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .mediumLarge\:px-5{
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .mediumLarge\:px-8{
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .mediumLarge\:pb-0{
    padding-bottom: 0px;
  }
  .mediumLarge\:pb-10{
    padding-bottom: 2.5rem;
  }
  .mediumLarge\:pb-\[4\.25rem\]{
    padding-bottom: 4.25rem;
  }
  .mediumLarge\:pl-3{
    padding-left: 0.75rem;
  }
  .mediumLarge\:pr-0{
    padding-right: 0px;
  }
  .mediumLarge\:pr-4{
    padding-right: 1rem;
  }
  .mediumLarge\:text-h4{
    font-size: clamp(1.375rem, 1.2647rem + 0.3676vw, 1.5rem);
    line-height: 1.17;
  }
  .mediumLarge\:blur-2xl{
    --tw-blur: blur(40px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }
  .mediumLarge\:\[mask-image\:linear-gradient\(to_left\2c transparent_0\%\2c black_8px\2c black_calc\(100\%-8px\)\2c transparent_100\%\)\]{
    -webkit-mask-image: linear-gradient(to left,transparent 0%,black 8px,black calc(100% - 8px),transparent 100%);
            mask-image: linear-gradient(to left,transparent 0%,black 8px,black calc(100% - 8px),transparent 100%);
  }
  .data-\[collapsed\=false\]\:mediumLarge\:grid-cols-\[1fr\][data-collapsed=false]{
    grid-template-columns: 1fr;
  }
  .data-\[text\=true\]\:mediumLarge\:grid-cols-\[1fr\][data-text=true]{
    grid-template-columns: 1fr;
  }
}
@media (min-width: 1200px){
  .min-\[1200px\]\:grid-cols-5{
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .min-\[1200px\]\:gap-x-\[2rem\]{
    -moz-column-gap: 2rem;
         column-gap: 2rem;
  }
  .min-\[1200px\]\:gap-y-12{
    row-gap: 3rem;
  }
}
@media (min-width: 1366px){
  .large\:absolute{
    position: absolute;
  }
  .large\:bottom-\[-10px\]{
    bottom: -10px;
  }
  .large\:left-8{
    left: 2rem;
  }
  .large\:top-\[82px\]{
    top: 82px;
  }
  .large\:mx-16{
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .large\:mt-20{
    margin-top: 5rem;
  }
  .large\:w-\[110\%\]{
    width: 110%;
  }
  .large\:w-\[calc\(50\%-1\.4rem\)\]{
    width: calc(50% - 1.4rem);
  }
  .large\:max-w-\[400px\]{
    max-width: 400px;
  }
  .large\:px-16{
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .large\:py-32{
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .data-\[glow\=true\]\:large\:shadow-shopCardExclusive[data-glow=true]{
    --tw-shadow: 0 0 20px 0 rgba(233, 168, 0, 0.75), 0 0 10px 0 rgba(233, 168, 0, 0.75);
    --tw-shadow-colored: 0 0 20px 0 var(--tw-shadow-color), 0 0 10px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}
@media (min-width: 1520px){
  .min-\[1520px\]\:block{
    display: block;
  }
}
@media (min-width: 768px){
  @media (orientation: portrait){
    .medium\:portrait\:mb-28{
      margin-bottom: 7rem;
    }
    .medium\:portrait\:pb-32{
      padding-bottom: 8rem;
    }
  }
}
@media (min-width: 1024px){
  @media (orientation: portrait){
    .mediumLarge\:portrait\:mb-40{
      margin-bottom: 10rem;
    }
  }
}
@media (orientation: landscape){
  .landscape\:mx-0{
    margin-left: 0px;
    margin-right: 0px;
  }
  @media not all and (min-width: 1024px){
    .landscape\:max-mediumLarge\:sticky{
      position: sticky;
    }
  }
}
@media not all and (min-width: 1024px){
  @media (orientation: landscape){
    .max-mediumLarge\:landscape\:top-16{
      top: 4rem;
    }
  }
}
@media (orientation: landscape){
  @media not all and (min-width: 1024px){
    .landscape\:max-mediumLarge\:mt-4{
      margin-top: 1rem;
    }
  }
}
@media not all and (min-width: 1024px){
  @media (orientation: landscape){
    .max-mediumLarge\:landscape\:mb-0{
      margin-bottom: 0px;
    }
    .max-mediumLarge\:landscape\:mb-4{
      margin-bottom: 1rem;
    }
  }
}
@media (orientation: landscape){
  @media not all and (min-width: 1024px){
    .landscape\:max-mediumLarge\:block{
      display: block;
    }
  }
}
@media not all and (min-width: 1024px){
  @media (orientation: landscape){
    .max-mediumLarge\:landscape\:block{
      display: block;
    }
  }
}
@media (orientation: landscape){
  @media not all and (min-width: 1024px){
    .landscape\:max-mediumLarge\:flex{
      display: flex;
    }
    .landscape\:max-mediumLarge\:grid{
      display: grid;
    }
    .landscape\:max-mediumLarge\:hidden{
      display: none;
    }
  }
}
@media not all and (min-width: 1024px){
  @media (orientation: landscape){
    .max-mediumLarge\:landscape\:hidden{
      display: none;
    }
    .max-mediumLarge\:landscape\:h-\[100px\]{
      height: 100px;
    }
  }
}
@media (orientation: landscape){
  @media not all and (min-width: 1024px){
    .landscape\:max-mediumLarge\:min-h-0{
      min-height: 0px;
    }
  }
}
@media not all and (min-width: 1024px){
  @media (orientation: landscape){
    .max-mediumLarge\:landscape\:min-h-\[290px\]{
      min-height: 290px;
    }
    .max-mediumLarge\:landscape\:min-w-\[350px\]{
      min-width: 350px;
    }
  }
}
@media (orientation: landscape){
  @media not all and (min-width: 1024px){
    .landscape\:max-mediumLarge\:max-w-64{
      max-width: 16rem;
    }
    .landscape\:max-mediumLarge\:max-w-\[284px\]{
      max-width: 284px;
    }
    .landscape\:max-mediumLarge\:max-w-\[650px\]{
      max-width: 650px;
    }
  }
}
@media not all and (min-width: 1024px){
  @media (orientation: landscape){
    .max-mediumLarge\:landscape\:max-w-\[360px\]{
      max-width: 360px;
    }
    .max-mediumLarge\:landscape\:flex-row{
      flex-direction: row;
    }
    .max-mediumLarge\:landscape\:items-start{
      align-items: flex-start;
    }
    .max-mediumLarge\:landscape\:justify-between{
      justify-content: space-between;
    }
  }
}
@media (orientation: landscape){
  @media not all and (min-width: 1024px){
    .landscape\:max-mediumLarge\:gap-8{
      gap: 2rem;
    }
  }
}
@media not all and (min-width: 1024px){
  @media (orientation: landscape){
    .max-mediumLarge\:landscape\:gap-10{
      gap: 2.5rem;
    }
  }
}
@media (orientation: landscape){
  @media not all and (min-width: 1024px){
    .landscape\:max-mediumLarge\:\!px-0{
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
    .landscape\:max-mediumLarge\:\!px-8{
      padding-left: 2rem !important;
      padding-right: 2rem !important;
    }
    .landscape\:max-mediumLarge\:pb-0{
      padding-bottom: 0px;
    }
    .landscape\:max-mediumLarge\:pt-0{
      padding-top: 0px;
    }
    .landscape\:max-mediumLarge\:pt-24{
      padding-top: 6rem;
    }
  }
}
@media not all and (min-width: 1024px){
  @media (orientation: landscape){
    .max-mediumLarge\:landscape\:pb-4{
      padding-bottom: 1rem;
    }
    .max-mediumLarge\:landscape\:pt-16{
      padding-top: 4rem;
    }
  }
}
@media (orientation: landscape){
  @media not all and (min-width: 768px){
    .landscape\:max-medium\:gap-0{
      gap: 0px;
    }
    .landscape\:max-medium\:px-0{
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}
@media not all and (min-width: 768px){
  @media (orientation: landscape){
    .max-medium\:landscape\:px-0{
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}
.placeholder-shown\:\[\&\:not\(\:focus\)\]\:h-\[50px\]:not(:focus):-moz-placeholder-shown{
  height: 50px;
}
.placeholder-shown\:\[\&\:not\(\:focus\)\]\:h-\[50px\]:not(:focus):placeholder-shown{
  height: 50px;
}
.placeholder-shown\:\[\&\:not\(\:focus\)\]\:overflow-hidden:not(:focus):-moz-placeholder-shown{
  overflow: hidden;
}
.placeholder-shown\:\[\&\:not\(\:focus\)\]\:overflow-hidden:not(:focus):placeholder-shown{
  overflow: hidden;
}
.\[\&\>div\>div\>canvas\]\:max-h-full>div>div>canvas{
  max-height: 100%;
}
.\[\&\>div\>div\>canvas\]\:max-w-full>div>div>canvas{
  max-width: 100%;
}
.\[\&\>svg\]\:min-h-6>svg{
  min-height: 1.5rem;
}
.\[\&\>svg\]\:min-w-6>svg{
  min-width: 1.5rem;
}
.\[\&\>svg\]\:cursor-pointer>svg{
  cursor: pointer;
}
.\[\&_\.flap-menu\]\:hidden .flap-menu{
  display: none;
}
.\[\&_\.flap-menu\]\:hover\:block:hover .flap-menu{
  display: block;
}
@media (pointer: coarse){
  .pointer-coarse\:\[\&_\.flap-menu\]\:block .flap-menu{
    display: block;
  }
}
.\[\&_label\]\:block label{
  display: block;
}
@media(max-height:480px){
  .\[\@media\(max-height\:480px\)\]\:sticky{
    position: sticky;
  }
  .\[\@media\(max-height\:480px\)\]\:top-\[72px\]{
    top: 72px;
  }
  .\[\@media\(max-height\:480px\)\]\:max-h-body{
    max-height: calc(100vh - 56px);
  }
  .\[\@media\(max-height\:480px\)\]\:data-\[hide-navigation\=true\]\:max-h-screen[data-hide-navigation=true]{
    max-height: 100vh;
  }
}
@media(min-height:1100px){
  .\[\@media\(min-height\:1100px\)\]\:pt-80{
    padding-top: 20rem;
  }
}
@media(min-height:354px){
  .\[\@media\(min-height\:354px\)\]\:bottom-0{
    bottom: 0px;
  }
}
@media(min-height:380px){
  .\[\@media\(min-height\:380px\)\]\:block{
    display: block;
  }
  .\[\@media\(min-height\:380px\)\]\:h-24{
    height: 6rem;
  }
  .\[\@media\(min-height\:380px\)\]\:w-24{
    width: 6rem;
  }
}
@media(min-height:460px){
  .\[\@media\(min-height\:460px\)\]\:mt-11{
    margin-top: 2.75rem;
  }
  .\[\@media\(min-height\:460px\)\]\:mt-12{
    margin-top: 3rem;
  }
  .\[\@media\(min-height\:460px\)\]\:mt-4{
    margin-top: 1rem;
  }
  .\[\@media\(min-height\:460px\)\]\:mt-6{
    margin-top: 1.5rem;
  }
  .\[\@media\(min-height\:460px\)\]\:block{
    display: block;
  }
  .\[\@media\(min-height\:460px\)\]\:filter-none{
    filter: none;
  }
  @media (min-width: 430px){
    .\[\@media\(min-height\:460px\)\]\:small\:h-\[120px\]{
      height: 120px;
    }
    .\[\@media\(min-height\:460px\)\]\:small\:w-\[196px\]{
      width: 196px;
    }
  }
  @media (min-width: 768px){
    .\[\@media\(min-height\:460px\)\]\:medium\:pt-\[102px\]{
      padding-top: 102px;
    }
  }
  @media (min-width: 830px){
    .\[\@media\(min-height\:460px\)\]\:breakMobile\:mt-16{
      margin-top: 4rem;
    }
  }
}
@media (min-width: 830px){
  @media(min-height:460px){
    .breakMobile\:\[\@media\(min-height\:460px\)\]\:py-8{
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }
}
@media(min-height:480px){
  .\[\@media\(min-height\:480px\)\]\:fixed{
    position: fixed;
  }
  .\[\@media\(min-height\:480px\)\]\:absolute{
    position: absolute;
  }
  .\[\@media\(min-height\:480px\)\]\:-bottom-3{
    bottom: -0.75rem;
  }
  .\[\@media\(min-height\:480px\)\]\:top-\[68px\]{
    top: 68px;
  }
  .\[\@media\(min-height\:480px\)\]\:ml-2{
    margin-left: 0.5rem;
  }
  .\[\@media\(min-height\:480px\)\]\:mr-0{
    margin-right: 0px;
  }
  .\[\@media\(min-height\:480px\)\]\:flex-col-reverse{
    flex-direction: column-reverse;
  }
  .\[\@media\(min-height\:480px\)\]\:self-center{
    align-self: center;
  }
  .\[\@media\(min-height\:480px\)\]\:from-greyscale-hiberBlack{
    --tw-gradient-from: #1B1C20 var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(27 28 32 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  }
  .\[\@media\(min-height\:480px\)\]\:from-\[-5\%\]{
    --tw-gradient-from-position: -5%;
  }
  .\[\@media\(min-height\:480px\)\]\:via-greyscale-veryDark{
    --tw-gradient-to: rgb(52 53 64 / 0)  var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), #343540 var(--tw-gradient-via-position), var(--tw-gradient-to);
  }
  .\[\@media\(min-height\:480px\)\]\:via-20\%{
    --tw-gradient-via-position: 20%;
  }
  .\[\@media\(min-height\:480px\)\]\:to-greyscale-almostBlack{
    --tw-gradient-to: #292A31 var(--tw-gradient-to-position);
  }
  .\[\@media\(min-height\:480px\)\]\:to-60\%{
    --tw-gradient-to-position: 60%;
  }
  .\[\@media\(min-height\:480px\)\]\:pb-4{
    padding-bottom: 1rem;
  }
  .\[\@media\(min-height\:480px\)\]\:data-\[hide-navigation\=true\]\:top-3[data-hide-navigation=true]{
    top: 0.75rem;
  }
}
@media (min-width: 768px){
  @media(min-height:480px){
    .medium\:\[\@media\(min-height\:480px\)\]\:min-h-4{
      min-height: 1rem;
    }
    .medium\:\[\@media\(min-height\:480px\)\]\:flex-row{
      flex-direction: row;
    }
    .medium\:\[\@media\(min-height\:480px\)\]\:gap-8{
      gap: 2rem;
    }
  }
}
@media (min-width: 1024px){
  @media(min-height:480px){
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:left-\[86px\]{
      left: 86px;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:right-1{
      right: 0.25rem;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:right-3{
      right: 0.75rem;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:top-3{
      top: 0.75rem;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:top-8{
      top: 2rem;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:ml-2{
      margin-left: 0.5rem;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:mt-0{
      margin-top: 0px;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:mt-4{
      margin-top: 1rem;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:block{
      display: block;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:flex{
      display: flex;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:hidden{
      display: none;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:h-5{
      height: 1.25rem;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:h-\[100px\]{
      height: 100px;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:h-\[200px\]{
      height: 200px;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:max-h-\[108px\]{
      max-height: 108px;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:max-h-body{
      max-height: calc(100vh - 56px);
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:min-h-\[100px\]{
      min-height: 100px;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:min-h-\[108px\]{
      min-height: 108px;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:min-h-\[120px\]{
      min-height: 120px;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:w-11{
      width: 2.75rem;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:w-20{
      width: 5rem;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:w-44{
      width: 11rem;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:min-w-20{
      min-width: 5rem;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:min-w-\[124px\]{
      min-width: 124px;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:max-w-\[560px\]{
      max-width: 560px;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:flex-shrink-0{
      flex-shrink: 0;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:grid-cols-\[repeat\(auto-fill\2c 80px\)\]{
      grid-template-columns: repeat(auto-fill,80px);
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:flex-row{
      flex-direction: row;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:items-center{
      align-items: center;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:justify-normal{
      justify-content: normal;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:justify-between{
      justify-content: space-between;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:gap-1{
      gap: 0.25rem;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:gap-x-4{
      -moz-column-gap: 1rem;
           column-gap: 1rem;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:gap-y-4{
      row-gap: 1rem;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:self-auto{
      align-self: auto;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:self-start{
      align-self: flex-start;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:bg-greyscale-almostBlack{
      --tw-bg-opacity: 1;
      background-color: rgb(41 42 49 / var(--tw-bg-opacity));
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:from-greyscale-almostBlack{
      --tw-gradient-from: #292A31 var(--tw-gradient-from-position);
      --tw-gradient-to: rgb(41 42 49 / 0) var(--tw-gradient-to-position);
      --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:from-0\%{
      --tw-gradient-from-position: 0%;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:via-greyscale-veryDark{
      --tw-gradient-to: rgb(52 53 64 / 0)  var(--tw-gradient-to-position);
      --tw-gradient-stops: var(--tw-gradient-from), #343540 var(--tw-gradient-via-position), var(--tw-gradient-to);
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:via-40\%{
      --tw-gradient-via-position: 40%;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:to-greyscale-hiberBlack{
      --tw-gradient-to: #1B1C20 var(--tw-gradient-to-position);
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:to-\[100\%\]{
      --tw-gradient-to-position: 100%;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:pl-2{
      padding-left: 0.5rem;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:pl-4{
      padding-left: 1rem;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:pl-\[71px\]{
      padding-left: 71px;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:pr-6{
      padding-right: 1.5rem;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:pr-8{
      padding-right: 2rem;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:pt-20{
      padding-top: 5rem;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:pt-4{
      padding-top: 1rem;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:text-p_small{
      font-size: 0.875rem;
      line-height: 1.57;
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:before\:content-\[\'You_have_a_classic_avatar_equipped\'\]::before{
      --tw-content: 'You have a classic avatar equipped';
      content: var(--tw-content);
    }
    .mediumLarge\:\[\@media\(min-height\:480px\)\]\:data-\[hide-navigation\=true\]\:max-h-screen[data-hide-navigation=true]{
      max-height: 100vh;
    }
  }
}
@media(min-height:550px){
  .\[\@media\(min-height\:550px\)\]\:bottom-20{
    bottom: 5rem;
  }
  .\[\@media\(min-height\:550px\)\]\:pt-32{
    padding-top: 8rem;
  }
}
@media(min-height:600px){
  .\[\@media\(min-height\:600px\)\]\:-top-10{
    top: -2.5rem;
  }
  .\[\@media\(min-height\:600px\)\]\:min-h-0{
    min-height: 0px;
  }
}
@media (min-width: 1024px){
  @media(min-height:600px){
    .mediumLarge\:\[\@media\(min-height\:600px\)\]\:mt-4{
      margin-top: 1rem;
    }
    .mediumLarge\:\[\@media\(min-height\:600px\)\]\:mt-6{
      margin-top: 1.5rem;
    }
    .mediumLarge\:\[\@media\(min-height\:600px\)\]\:block{
      display: block;
    }
  }
}
@media(min-height:600px){
  @media (min-width: 1024px){
    .\[\@media\(min-height\:600px\)\]\:mediumLarge\:h-\[40vh\]{
      height: 40vh;
    }
  }
}
@media(min-height:720px){
  .\[\@media\(min-height\:720px\)\]\:top-20{
    top: 5rem;
  }
}
@media(min-height:760px){
  .\[\@media\(min-height\:760px\)\]\:pt-28{
    padding-top: 7rem;
  }
  .\[\@media\(min-height\:760px\)\]\:pt-40{
    padding-top: 10rem;
  }
  .\[\@media\(min-height\:760px\)\]\:pt-44{
    padding-top: 11rem;
  }
  .\[\@media\(min-height\:760px\)\]\:pt-60{
    padding-top: 15rem;
  }
}
@media(min-height:950px){
  .\[\@media\(min-height\:950px\)\]\:pt-56{
    padding-top: 14rem;
  }
}
@media(min-width:1104px){
  .\[\@media\(min-width\:1104px\)\]\:w-\[unset\]{
    width: unset;
  }
}

/* cyrillic-ext */
@font-face {
  font-family: '__Inter_f50a95';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_f50a95';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_f50a95';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_f50a95';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_f50a95';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_f50a95';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_f50a95';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_f50a95';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_f50a95';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_f50a95';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_f50a95';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_f50a95';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_f50a95';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_f50a95';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_f50a95';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_f50a95';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_f50a95';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_f50a95';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_f50a95';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_f50a95';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_f50a95';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_f50a95';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_f50a95';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_f50a95';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_f50a95';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_f50a95';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_f50a95';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_f50a95';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_f50a95';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_f50a95';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_f50a95';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_f50a95';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_f50a95';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_f50a95';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_f50a95';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_f50a95';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_f50a95';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_f50a95';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_f50a95';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_f50a95';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_f50a95';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_f50a95';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_f50a95';src: local("Arial");ascent-override: 90.20%;descent-override: 22.48%;line-gap-override: 0.00%;size-adjust: 107.40%
}.__className_f50a95 {font-family: '__Inter_f50a95', '__Inter_Fallback_f50a95';font-style: normal
}

